import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { join_route, member_join_type } from "../../../model/User";
import { UserDetailState } from "../../../states/UserState";

export default function UserDetailTableEl() {
  const classes = useStyle();
  const userDetail = useRecoilValue(UserDetailState);

  function joinType(member_join_type: member_join_type): string {
    let result = ''
    switch (member_join_type) {
      case 'N' || 'AN':
        result = '네이버';
        break;
      case 'K' || 'AK':
        result = '카카오';
        break;
      case 'C':
        result = '키토테이블';
        break
    }
    return result;
  }

  function joinRoute(join_route: join_route): string {
    let result = '';
    switch (join_route) {
      case 1:
        result = '인아웃';
        break
      case 0:
        result = '본점';
        break
    }
    return result;
  }

  return (
    <>
      <Grid className={classes.table}>
        <p>회원 ID</p>
        <div/>
        <span>{userDetail.member_id}</span>
        <div/>
        <p>회원 이름</p>
        <div/>
        <span>{userDetail.member_name}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>전화번호</p>
        <div/>
        <span>{userDetail.member_phone}</span>
        <div/>
        <p>E-mail</p>
        <div/>
        <span>{userDetail.member_email}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>성별</p>
        <div/>
        <span>{userDetail.gender}</span>
        <div/>
        <p>키</p>
        <div/>
        <span>{userDetail.height}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>활동량</p>
        <div/>
        <span>{userDetail.status}</span>
        <div/>
        <p>가입 경로</p>
        <div/>
        <span>{joinRoute(userDetail.join_route)} ({joinType(userDetail.member_join_type)})</span>
      </Grid>
      <div className={classes.line}/>
    </>
    
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    table: {
      width: '90%',
      height: 35,
      margin: 'auto',
      background: '#fff',
      display: 'grid',
      gridTemplateColumns: '17% 0.1% 32% 0.1% 17% 0.1% 32%',
      justifyItems: 'center',
      alignItems: 'center',
      '& p': {
        width: '100%',
        fontSize: 13,
        fontWeight: 500,
        color: '#414141',
        textAlign: 'center',
        padding: '9.5px 0',
        margin: 0,

        background: '#dddddd'
      },
      '& span': {
        fontSize: 12,
        fontWeight: 400,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& div': {
        width: '100%',
        height: 35,
        border: 'none',
        background: '#999999',
      },
    },
    line: {
      width: '90%',
      margin: 'auto',
      height: 1,
      background: '#afafaf',
    }
  })
)