import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { foodDetailState } from "../../../states/FoodState";

export default function FoodDetailHeader() {
  const classes = useStyle()
  const foodDetailData = useRecoilValue(foodDetailState);

  console.log(foodDetailData.food_img === null)

  return (
    <Grid className={classes.container} container justifyContent='space-between' alignItems='center'>

      <Grid className={classes.content} item>
        <div className={classes.line}/>
        <Grid className={classes.table}>
          <p>No</p>
          <div/>
          <span>{foodDetailData.food_idx}</span>
        </Grid>
        <div className={classes.line}/>
        <Grid className={classes.table}>
          <p>음식 ID</p>
          <div/>
          <span>{foodDetailData.food_id}</span>
          <div/>
        </Grid>
        <div className={classes.line}/>
        <Grid className={classes.table}>
          <p>음식 이름</p>
          <div/>
          <span>{foodDetailData.food_name}</span>
        </Grid>
        <div className={classes.line}/>
      </Grid>

      <Grid className={classes.content}>
        {foodDetailData.food_img !== null ? 
          <img src={foodDetailData.food_img} alt={foodDetailData.food_name}/>
          :
          <Grid 
            style={{background: '#8b8b8b', height: '100px'}}
            container
            justifyContent='center'
            alignItems='center'
          >
            <p style={{color: '#fff'}}>이미지가 없습니다</p>
          </Grid>
        }
        </Grid>
    
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      width: '90%',
      margin: 'auto',
    },
    content: {
      width: '50%',

    },
    table: {
      width: '90%',
      height: 35,
      background: '#fff',
      display: 'grid',
      gridTemplateColumns: '40% 0.5% 50%',
      justifyItems: 'center',
      alignItems: 'center',
      '& p': {
        width: '100%',
        fontSize: 13,
        fontWeight: 500,
        color: '#414141',
        textAlign: 'center',
        padding: '9.5px 0',
        margin: 0,
        background: '#dddddd'
      },
      '& div': {
        width: '100%',
        height: 35,
        border: 'none',
        background: '#999999',
      },
      '& span': {
        fontSize: 12,
        fontWeight: 400,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }
    },
    line: {
      width: '90%',
      height: 1,
      background: '#afafaf',
    }
  })
)