import { makeStyles, Grid, createStyles } from "@material-ui/core";
import Header from "../../shared/Header";
import SideNav from "../../shared/SideNav";
import HeaderEl from "../../Components/HeaderEl";
import ReviewForm from "./components/ReviewForm";

export default function Review() {
  const classes = useStyle();

  return (
    <>
      <Header/>
      <SideNav/>
      <HeaderEl title='리뷰 관리'/>
      <Grid className={classes.container}>
        <ReviewForm/>
      </Grid>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      width: '70%',
      margin: 'auto',
    }
  })
)