import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { getAdminDashboardDiary } from "../../../apis/dashboard";
import { dashboardDiaryState } from "../../../states/DashboardState";

export default function DashboardDiary() {
  const classes = useStyle();
  const [dashboardDiaryData, setDashboardDiaryData] = useRecoilState(dashboardDiaryState);

  useEffect(() => {
    (async () => {
      if (dashboardDiaryData === undefined) {
        const data = await getAdminDashboardDiary();
        console.log(data);
        setDashboardDiaryData(data.count[0])
      }
    })();
  }, [])

  return (
    <Grid
      className={classes.container}
    >
      <Grid>
        <Grid className={classes.header}>
          <p>월간 기록 수</p>
        </Grid>

        <Grid>
          <Grid className={classes.table}>
            <p>아침</p>
            <div/>
            <p>점심</p>
            <div/>
            <p>저녁</p>
            <div/>
            <p>간식</p>
            <div/>
            <p>이미지</p>
          </Grid>
          <Grid className={classes.table} style={{background: '#fff'}}>
            <span>{dashboardDiaryData?.menuMorning} 건</span>
            <div/>
            <span>{dashboardDiaryData?.menuLunch} 건</span>
            <div/>
            <span>{dashboardDiaryData?.menuDinner} 건</span>
            <div/>
            <span>{dashboardDiaryData?.menuSnack} 건</span>
            <div/>
            <span>{dashboardDiaryData?.menuImg} 건</span>
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        <Grid className={classes.header}>
          <p>잠깐만!!</p>
        </Grid>

        <Grid>
          <Grid className={classes.table}>
            <p>컨셉</p>
            <div/>
            <p>패턴</p>
            <div/>
            <p>키토시스</p>
          </Grid>
          <Grid className={classes.table} style={{background: '#fff'}}>
            <span>{dashboardDiaryData?.concept} 건</span>
            <div/>
            <span>{dashboardDiaryData?.pattern} 건</span>
            <div/>
            <span>{dashboardDiaryData?.ketosis} 건</span>
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        <Grid className={classes.header}>
          <p>특별한 점</p>
        </Grid>

        <Grid>
          <Grid className={classes.table}>
            <p>몸상태</p>
            <div/>
            <p>알약</p>
            <div/>
            <p>메모</p>

          </Grid>
          <Grid className={classes.table} style={{background: '#fff'}}>
          <span>{dashboardDiaryData?.tagBodyCondition} 건</span>
            <div/>
            <span>{dashboardDiaryData?.tagSupplements} 건</span>
            <div/>
            <span>{dashboardDiaryData?.tagMemo} 건</span>
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        <Grid className={classes.header}>
          <p>유저등록</p>
        </Grid>

        <Grid>
          <Grid className={classes.table}>
            <p>커스텀 음식등록</p>
            <div/>
            <p>음식 편집</p>
          </Grid>
          <Grid className={classes.table} style={{background: '#fff'}}>
            <span>{dashboardDiaryData?.editCreate} 건</span>
            <div/>
            <span>{dashboardDiaryData?.editPatch} 건</span>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      width: '100%',
      margin: '20px auto',
      padding: 20,
      background: '#fff',
      boxShadow: '0 10px 30px rgb(0 0 0 / 20%)',
    },
    header: {
      width: '90%',
      margin: 'auto',
      '& p': {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#4b4b4b',
      }
    },
    table: {
      width: '90%',
      height: 50,
      margin: 'auto',
      background: '#dddddd',
      display: 'grid',
      gridTemplateColumns: '19.8% 0.25% 19.8% 0.25% 19.8% 0.25% 19.8% 0.25% 19.8%',
      alignItems: 'center',
      '& p': {
        fontSize: 14,
        fontWeight: 500,
        color: '#414141',
        textAlign: 'center',
        margin: 0,
      },
      '& span': {
        fontSize: 16,
        fontWeight: 400,
        color: '#747474',
        textAlign: 'center',
      },
      '& div': {
        width: 1,
        height: 50,
        border: 'none',
        background: '#999999',
      },
    },

  })
)