import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { MdSpaceDashboard, MdOutlineSpaceDashboard, MdFastfood, MdOutlineFastfood, MdLocalGroceryStore, MdOutlineLocalGroceryStore, MdOutlineRateReview, MdRateReview } from "react-icons/md";
import { RiKakaoTalkFill, RiKakaoTalkLine, RiUser3Fill, RiUser3Line } from "react-icons/ri";
import { BsCheckCircleFill, BsCheckCircle } from "react-icons/bs";
import { useHistory } from "react-router";

export default function SideNav(Props: any) {
  const classes = useStyle();


  return (
    <Grid className={classes.container}>
      <NavTab text='DashBoard' url='/' />
      <NavTab text='Users' url='/users' />
      <NavTab text='Foods' url='/foods' />
      <NavTab text='InoutTalk' url='/inoutTalk' />
      <NavTab text='Store' url='/store' />
      <NavTab text='Confirm' url='/confirm' />
      <NavTab text='Review' url='/review' />
    </Grid>
  );
}

interface NavTabProps {
  text: string;
  url: string;
}

export function NavTab({text, url}: NavTabProps) {
  const classes = useStyle();
  const path = window.location.pathname;
  const history = useHistory();

  function MoveTo() {
    switch (text) {
      case 'DashBoard':
        return history.push('/');
      case 'Users':
        return history.push('/users');
      case 'Foods':
        return history.push('/foods');
      case 'InoutTalk':
        return history.push('/inoutTalk');
      case 'Store':
        return history.push('/store');
      case 'Confirm':
        return history.push('/confirm');
      case 'Review':
        return history.push('/review');
    }
  }

  function SideIcon() {
    switch (text) {
      case 'DashBoard':
        return path === '/' ? {
          icon: <MdSpaceDashboard color='#fff' size='24'/>,
          background: '#64618d',
        } : {
          icon: <MdOutlineSpaceDashboard color='#fff' size='24'/>,
          background: '#403d5f',
        }
      case 'Users':
        return path === '/users' ? {
          icon: <RiUser3Fill color='#fff' size='24'/>,
          background: '#64618d',
        }
          : 
        {
          icon: <RiUser3Line color='#fff' size='24'/>,
          background: '#403d5f',
        }
      case 'Foods':
        return path === '/foods' ? {
          icon: <MdFastfood color='#fff' size='24'/>,
          background: '#64618d',
        }
          : 
        {
          icon: <MdOutlineFastfood color='#fff' size='24'/>,
          background: '#403d5f',
        }
      case 'InoutTalk':
        return path === '/inoutTalk' ? {
          icon: <RiKakaoTalkFill color='#fff' size='24'/>,
          background: '#64618d',
        }
          : 
        {  
          icon: <RiKakaoTalkLine color='#fff' size='24'/>,
          background: '#403d5f',
        }
      case 'Store':
        return path === '/store' ? {
        icon: <MdLocalGroceryStore color='#fff' size='24'/>,
        background: '#64618d',
      }
        : 
      {
        icon: <MdOutlineLocalGroceryStore color='#fff' size='24'/>,
        background: '#403d5f',
      }
      case 'Confirm':
        return path === '/confirm' ? {
          icon: <BsCheckCircleFill color='#fff' size='24'/>,
          background: '#64618d',
        }
          : 
        {
          icon: <BsCheckCircle color='#fff' size='24'/>,
          background: '#403d5f',
        }
      case 'Review':
        return path === '/review' ? {
          icon: <MdRateReview color='#fff' size='24'/>,
          background: '#64618d',
        }
          : 
        {
          icon: <MdOutlineRateReview color='#fff' size='24'/>,
          background: '#403d5f',
        }
    }
  }

  return (
    <Grid 
      className={classes.nav} 
      container 
      alignItems='center' 
      onClick={() => MoveTo()}
      style={{background: SideIcon()?.background}}
    >
      <Grid item>
        {SideIcon()?.icon}
      </Grid>
      <span>{text}</span>
    </Grid>
  );
}


const useStyle = makeStyles(() => 
  createStyles({
    container: {
      position: 'fixed',
      top: 70,
      left: 0,
      width: 300,
      height: '100%',
      backgroundColor: '#403d5f',
      
    },
    navHeader: {
      backgroundColor: '#403d5f',
      color: '#e9e9e9',
      padding: '8px 16px',
    },
    nav: {
      width: '100%',
      padding: '13px 13px',
      cursor: 'pointer',
      '& div': {
        marginTop: 4,
      },
      '& span': {
        fontSize: 15,
        color: '#e9e9e9',
        margin: '7px 0 7px 12px',
      }
    }
  })
)