import { atom } from "recoil";
import { ArrProduct, ArrPromotion } from "../model/Store";

export const StoreProductState = atom<ArrProduct>({
  key: 'StoreProductState',
  default: []
})

export const StorePromotionState = atom<ArrPromotion>({
  key: 'StorePromotionState',
  default: []
})

export const PromotionEditState = atom<boolean>({
  key: 'PromotionEditState',
  default: false,
})

export const PromotionModalState = atom<boolean>({
  key: 'PromotionModalState',
  default: false,
})

export const PromotionEditIdState = atom<number | null>({
  key: 'PromotionEditIdState',
  default: null,
})