import { request } from "http";
import { ConfirmDetailData, ConfirmDetailResponse, ConfirmListData, ConfirmRequest, ResponseMsg } from "../model/Confirm";
import { get, post, del } from "../module/HttpWebClient"

export async function getAdminConfirmList(page: number, filter: number): Promise<ConfirmListData> {
  const url = `/admin/edit/list/${page}/${filter}`;
  const { data } = await get<ConfirmListData>(url);
  return data;
}

export async function getAdminConfirmDetail(edit_idx: number): Promise<ConfirmDetailResponse> {
  const url = `/admin/edit/list/detail/info/${edit_idx}`;
  const { data } = await get<ConfirmDetailResponse>(url);
  return data;
}

export async function setAdminConfirm(request: ConfirmRequest): Promise<ResponseMsg> {
  const url = '/admin/edit/foodEdit';
  const { data } = await post<ResponseMsg>(url, {
    ...request
  });
  return data;
}

export async function delAdminConfirm(edit_idx: number, rejectContent: string): Promise<ResponseMsg> {
  const url = `/admin/edit/foodEdit/${edit_idx}`;
  const { data } = await post<ResponseMsg>(url, {
    rejectContent: rejectContent,
  });
  return data;
}