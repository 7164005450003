import { makeStyles, Grid, createStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { editAdminFood, setAdminCustomFood } from "../../../apis/food";
import { foodDetailState, foodModalState, foodModalTypeState, foodNormalListState } from "../../../states/FoodState";
import InputBox from "../elements/InputBox";

export default function FoodInputContainer() {
  const classes = useStyle();
  const setOpenModal = useSetRecoilState(foodModalState);
  const [typeModal, setTypeModal] = useRecoilState(foodModalTypeState);
  const [foodDetail, setFoodDetail] = useRecoilState(foodDetailState);

  let request = {
    food_name: '',
    food_type: '',
    food_brand: '',
    measurement_description: '',
    metric_serving_unit: '',
    metric_serving_amount: '',
    number_of_units: 1,
    calories: 0,
    carbohydrate: 0,
    sugar: 0,
    sugar_sub: 0,
    fiber: 0,
    protein: 0,
    fat: 0,
    saturated_fat: 0,
    monounsaturated_fat: 0,
    polyunsaturated_fat: 0,
    trans_fat: 0,
    sodium: 0,
    cholesterol: 0,
    potassium: 0,
  }
  const [requestForm, setRequestForm] = useState(request);

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, food_name: e.target.value})
    :
    setFoodDetail({...foodDetail, food_name: e.target.value})
  }
  const changeType = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, food_type: e.target.value})
    :
    setFoodDetail({...foodDetail, food_type: e.target.value})
  }
  const changeBrand = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, food_brand: e.target.value})
    :
    setFoodDetail({...foodDetail, food_brand: e.target.value})
  }
  const changeMeasurement = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, measurement_description: e.target.value})
    :
    setFoodDetail({...foodDetail, measurement_description: e.target.value})
  }
  const changeUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, metric_serving_unit: e.target.value})
    :
    setFoodDetail({...foodDetail, metric_serving_unit: e.target.value})
  }
  const changeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, metric_serving_amount: e.target.value})
    :
    setFoodDetail({...foodDetail, metric_serving_amount: e.target.value})
  }
  const changeCalories = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, calories: Number(e.target.value)})
    :
    setFoodDetail({...foodDetail, calories: Number(e.target.value)})
  }
  const changeCarbo = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, carbohydrate: Number(e.target.value)})
    :
    setFoodDetail({...foodDetail, carbohydrate: Number(e.target.value)})
  }
  const changeSugar = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, sugar: Number(e.target.value)})
    :
    setFoodDetail({...foodDetail, sugar: Number(e.target.value)})
  }
  const changeSugarSub = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, sugar_sub: Number(e.target.value)})
    :
    setFoodDetail({...foodDetail, sugar_sub: Number(e.target.value)})
  }
  const changeFiber = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, fiber: Number(e.target.value)})
    :
    setFoodDetail({...foodDetail, fiber: Number(e.target.value)})
  }
  const changeProtein = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, protein: Number(e.target.value)})
    :
    setFoodDetail({...foodDetail, protein: Number(e.target.value)})
  }
  const changeFat = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, fat: Number(e.target.value)})
    :
    setFoodDetail({...foodDetail, fat: Number(e.target.value)})
  }
  const changeSatFat = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, saturated_fat: Number(e.target.value)})
    :
    setFoodDetail({...foodDetail, saturated_fat: Number(e.target.value)})
  }
  const changeMonoFat = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, monounsaturated_fat: Number(e.target.value)})
    :
    setFoodDetail({...foodDetail, monounsaturated_fat: Number(e.target.value)})
  }
  const changePolyFat = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, polyunsaturated_fat: Number(e.target.value)})
    :
    setFoodDetail({...foodDetail, polyunsaturated_fat: Number(e.target.value)})
  }
  const changeTransFat = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, trans_fat: Number(e.target.value)})
    :
    setFoodDetail({...foodDetail, trans_fat: Number(e.target.value)})
  }
  const changeSodium = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, sodium: Number(e.target.value)})
    :
    setFoodDetail({...foodDetail, sodium: Number(e.target.value)})
  }
  const changeChole = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, cholesterol: Number(e.target.value)})
    :
    setFoodDetail({...foodDetail, cholesterol: Number(e.target.value)})
  }
  const changePotas = (e: React.ChangeEvent<HTMLInputElement>) => {
    typeModal === 1 ? 
    setRequestForm({...requestForm, potassium: Number(e.target.value)})
    :
    setFoodDetail({...foodDetail, potassium: Number(e.target.value)})
  }

  const createFood = async () => {
    if (typeModal === 1) {
      const data = await setAdminCustomFood(requestForm);
      console.log(data);
    } else {
      const data = await editAdminFood(foodDetail);
      console.log(data)
      // const currentIndex = foodNormalList.findIndex(food => food.food_idx === foodDetail.food_idx);
      // const copiedList = [...foodNormalList];
      // const changedData = {
      //   reg_count: 1,
      //   grade_avg: '-',
      //   review_count: 1,
      //   food_idx: foodDetail.food_idx,
      //   food_id: foodDetail.food_id,
      //   food_name: foodDetail.food_name,
      //   creator: foodDetail.creator,
      // };
      // copiedList[currentIndex] = changedData;
      // setFoodNormalList(copiedList);
    }
    setOpenModal(false);
    setTypeModal(0);
  }

  let isEdit = typeModal === 2;

  return (
    <Grid className={classes.container}>
      <InputBox type='text' placeholder='음식명을 입력해주세요' setState={changeName} value={isEdit && foodDetail.food_name}/>
      <InputBox type='text' placeholder='음식타입을 입력해주세요 "Brand" or "Generic"' setState={changeType} value={isEdit && foodDetail.food_type}/>
      <InputBox type='text' placeholder='브랜드를 입력해주세요' setState={changeBrand} value={isEdit && foodDetail.food_brand}/>
      <InputBox type='text' placeholder='음식단위를 입력해주세요 ex) 조각, 인분 등' setState={changeMeasurement} value={isEdit && foodDetail.measurement_description}/>
      <InputBox type='text' placeholder='미터법 단위를 입력해주세요' setState={changeUnit} value={isEdit && foodDetail.metric_serving_unit}/>
      <InputBox type='text' placeholder='미터법 용량을 입력해주세요' setState={changeAmount} value={isEdit && foodDetail.metric_serving_amount}/>
      <InputBox type='number' placeholder='칼로리를 입력해주세요' setState={changeCalories} value={isEdit && foodDetail.calories}/>
      <InputBox type='number' placeholder='탄수화물을 입력해주세요' setState={changeCarbo} value={isEdit && foodDetail.carbohydrate}/>
      <InputBox type='number' placeholder='당을 입력해주세요' setState={changeSugar} value={isEdit && foodDetail.sugar}/>
      <InputBox type='number' placeholder='대체당을 입력해주세요' setState={changeSugarSub} value={isEdit && foodDetail.sugar_sub}/>
      <InputBox type='number' placeholder='식이섬유를 입력해주세요' setState={changeFiber} value={isEdit && foodDetail.fiber}/>
      <InputBox type='number' placeholder='단백질을 입력해주세요' setState={changeProtein} value={isEdit && foodDetail.protein}/>  
      <InputBox type='number' placeholder='지방을 입력해주세요' setState={changeFat} value={isEdit && foodDetail.fat}/>
      <InputBox type='number' placeholder='포화 지방을 입력해주세요' setState={changeSatFat} value={isEdit && foodDetail.saturated_fat}/>
      <InputBox type='number' placeholder='불포화 지방을 입력해주세요' setState={changeMonoFat} value={isEdit && foodDetail.monounsaturated_fat}/>
      <InputBox type='number' placeholder='다포화 지방을 입력해주세요' setState={changePolyFat} value={isEdit && foodDetail.polyunsaturated_fat}/>
      <InputBox type='number' placeholder='트랜스 지방을 입력해주세요' setState={changeTransFat} value={isEdit && foodDetail.trans_fat}/>
      <InputBox type='number' placeholder='나트륨을 입력해주세요' setState={changeSodium} value={isEdit && foodDetail.sodium}/>
      <InputBox type='number' placeholder='콜레스테롤을 입력해주세요' setState={changeChole} value={isEdit && foodDetail.cholesterol}/>
      <InputBox type='number' placeholder='칼륨을 입력해주세요' setState={changePotas} value={isEdit && foodDetail.potassium}/>


      <Grid 
        className={classes.createBtn}
        container 
        justifyContent='center' 
        alignItems='center'
        onClick={createFood}
      >
        <span>{typeModal === 1 ? '등록 하기' : '수정 하기'}</span>
      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      width: '70%',
      margin: 'auto',
    },
    createBtn: {
      width: 100,
      height: 30,
      background: '#67a56f',
      borderRadius: 5,
      cursor: 'pointer',
      margin: 'auto',
      '& span': {
        fontSize: 14,
        color: '#fff',
      }
    }
  })
)