import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { TiDelete } from "react-icons/ti";
import { useRecoilState } from "recoil";
import { delAdminProduct } from "../../../apis/store";
import { StoreProductData } from "../../../model/Store";
import { StoreProductState } from "../../../states/StoreState";

export default function ProductItem({created_at, product_url, product_idx, product_img, product_name, product_ranking}: StoreProductData) {
  const classes = useStyle();
  const [productList, setProductList] = useRecoilState(StoreProductState);

  const delProduct = async () => {
    if (window.confirm('인기상품을 삭제하시겠습니까?')) {
      const data = await delAdminProduct(product_idx);
      console.log(data);
      let deletedList = productList.filter((item) => item.product_idx !== product_idx);
      setProductList(deletedList);
    }
  }

  return (
    <Grid className={classes.container} container justifyContent='space-between' alignItems='center'>
      <div className={classes.circle} style={{backgroundImage: `url(${product_img})`, backgroundSize: '100%'}}/>

      <Grid className={classes.content} item>
        <Grid><span>상품명 : {product_name}</span></Grid>
        <Grid><span>링크 : {product_url}</span></Grid>
        <Grid><span>생성일 : {created_at}</span></Grid>
      </Grid>
      
      <Grid className={classes.delBtn} onClick={delProduct} container item justifyContent='center' alignItems='center'>
        <TiDelete size='24' color='#777'/>
      </Grid>

      <Grid className={classes.rank}>
        <p style={product_ranking + 1 === 1 ? {color: '#ff4800'} : product_ranking + 1 === 2 || product_ranking + 1 === 3 ? {color: '#000'} : {}}>
          {product_ranking+1}
        </p>
      </Grid>

    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      position: 'relative',
      width: '60%',
      height: 120,
      margin: '10px auto',
      padding: '0 20px',
      boxShadow: '0 5px 10px rgb(0 0 0 / 10%)',
    },
    circle: {
      width: 90,
      height: 90,
      borderRadius: '50%',
      margin: '0 20px' 
    },
    content: {
      width: 'calc(100% - 200px)',
      padding: 10,
      '& div': {
        padding: '5px 0'
      }
    },
    rank: {
      position: 'absolute',
      top: 0,
      left: 5,
      '& p': {
        fontSize: 25,
        fontWeight: 500,
        fontStyle: 'italic',
        textAlign: 'center',
        lineHeight: '1',
        color: '#999fab'
      }
    },
    delBtn: {
      width: 70,
      height: 70,
      paddingBottom: 55,
      cursor: 'pointer',
    }
  })
)