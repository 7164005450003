import { makeStyles, createStyles, Grid } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import LabelInput from "../../Components/elements/LabelInput";
import HeaderEl from "../../Components/HeaderEl";
import { InoutTalkRequestData, SubTitleRequest } from "../../model/InoutTalk";
import Header from "../../shared/Header";
import SideNav from "../../shared/SideNav";
import InoutSubForm from "./elements/InoutSubForm";
import { TiDelete } from "react-icons/ti";
import SearchContainer from "./components/SearchContainer";
import moment from "moment";
import SubmitButton from "../../Components/elements/SubmitButton";
import { setAdminInoutTalk } from "../../apis/inoutTalk";
import { useHistory } from "react-router";
import { AiOutlineClose } from "react-icons/ai";


export default function RegisterInoutTalk() {
  const classes = useStyles();
  const history = useHistory();
  const requestData = {
    mainTitleName: '',
    created_at: moment().format('yyyy-mm-dd'),
    subTitle: [],
  }
  const content = {
    name: '',
    food_id: [],
    food_idx: [],
    item_names: [],
  }
  const [subContent, setSubContent] = useState<SubTitleRequest>(content);
  const [request, setRequest] = useState<InoutTalkRequestData>(requestData);
  console.log(subContent.name)
  const submitInoutTalk = async () => {
    if (request.mainTitleName === '') {
      window.alert("메인타이틀을 입력해주세요")
      return;
    } else if (request.subTitle.length === 0) {
      window.alert("서브타이틀을 채워주세요")
      return;
    }
    const data = await setAdminInoutTalk(request);
    if (data) {
      history.go(-1);
    }
  }

  const delItem = (id: number) => {
    const idx = subContent.food_id.findIndex((item) => item === id)
    const copyContent = {...subContent};
    copyContent.food_id.splice(idx, 1)
    copyContent.food_idx.splice(idx, 1)
    copyContent.item_names.splice(idx, 1)
    setSubContent({...copyContent})
  }

  const delSub = (name: string) => {
    setRequest({
      ...request, subTitle: [...request.subTitle.filter((item) => item.name !== name)]
    })
  }

  const saveSubData = () => {
    if (request.subTitle.length === 4) {
      window.alert("서브컨텐츠가 이미 가득 찼습니다");
      return;
    } else if (subContent.name === '') {
      window.alert("서브타이틀을 입력해주세요")
      return;
    } else if (subContent.item_names.length === 0) {
      window.alert("아이템을 채워주세요")
      return;
    }
    setRequest({...request, subTitle: [...request.subTitle, subContent]})
    setSubContent(content);
  }

  const mainTitleHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setRequest({...request, mainTitleName: e.target.value})
  }

  const subTitleHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setSubContent({...subContent, name: e.target.value});
  }

  return (
    <>
      <Header/>
      <SideNav/>
      <HeaderEl title='인아웃토크 등록'/>
      <Grid className={classes.container}>

        <Grid className={classes.body}>
          
          <Grid>
            <Grid 
              container
              justify={'space-between'}
              alignItems={'center'}
              className={classes.titleHeader}
            >
              <p>메인 타이틀</p>
              <SubmitButton onClick={submitInoutTalk}>인아웃토크 등록하기</SubmitButton>
            </Grid>
            <Grid className={classes.inputContainer}>
              <input
                onChange={mainTitleHandler}
                placeholder="메인타이틀을 입력해주세요"
              />
            </Grid>
          </Grid>

          <Grid >
            <Grid 
              className={classes.subContainer}
              container
              alignItems={'center'}
              justify={'flex-start'}
            >
              {request.subTitle.map((item, index) => {
                return <SubItems key={index} index={index} item={item} delSub={delSub}/>
              })}
              {request.subTitle.length === 0 && <span>서브타이틀을 추가해주세요</span>}
            </Grid>
          </Grid>

          <Grid>
            <Grid>
              <Grid 
              className={classes.titleHeader}
              container 
              alignItems={'center'} 
              justify={'space-between'}
              >
                <p>서브 타이틀 등록</p>
                <SubmitButton onClick={saveSubData}>서브컨텐츠 추가하기</SubmitButton>
              </Grid>
              <Grid className={classes.textContainer}>
                <textarea
                  onChange={subTitleHandler}
                  placeholder="서브타이틀을 입력해주세요"
                  value={subContent.name}
                  rows={4}
                />
              </Grid>
            </Grid>

            <Grid>
              <Grid 
                className={classes.itemContainer}
                container
              >
                {subContent.item_names.map((item, index) => {
                  return (
                    <Grid className={classes.item} key={index} container justify={'space-between'} alignItems={'center'}>
                      <p>{item}</p>
                      <TiDelete color={'#fff'} onClick={()=>delItem(subContent.food_id[index])}/>
                    </Grid>
                  )
                })}
                {subContent.item_names.length === 0 && <span>아이템을 추가해주세요</span>}
              </Grid>
            </Grid>
          
          </Grid>

            <SearchContainer 
              setItems={setSubContent}
              items={subContent}
            />
        </Grid>
      </Grid>
    </>
  )
}

interface Props {
  item: SubTitleRequest
  index: number;
  delSub(name: string): void; 
}

export function SubItems({item, index, delSub}: Props) {
  const classes = useStyles();

  const backColor = () => {
    const result = '#544cc3'
    switch (index) {
      case 0:
        return '#544cc3be';
      case 1:
        return '#e26355c5';
      case 2:
        return '#82c7d2c1';
      case 3:
        return '#8796d7c3';
    }
    return result;
  }

  return (
    <Grid className={classes.subItem} style={{background: backColor()}}>
      <p>{item.name}</p>
      <p>{item.item_names.length}개</p>
      <AiOutlineClose color='#fff' className={classes.delBtn} onClick={()=>delSub(item.name)}/>
    </Grid>
  )
}

const useStyles = makeStyles(() => 
  createStyles({
    container: {
      position: 'relative',
      padding: '20px 50px',
    },
    body: {
      width: '80%',
      height: '100%',
      margin: 'auto',
    },
    item: {
      width: 'auto',
      height: 30,
      borderRadius: 20,
      background: '#9091cc',
      padding: '0 10px',
      margin: 5,
      columnGap: 10,
      '& p': {
        margin: 0,
        fontSize: 12,
        fontWeight: 500,
        color: '#fff',
      }
    },
    subContainer: {
      width: '80%',
      margin: '10px auto',
      marginBottom: 30,
      background: '#d6d6d6',
      height: 180,
      borderRadius: 20,
      padding: 20,
      columnGap: 20,
      '& span': {
        display: 'block',
        width: '100%',
        textAlign: 'center',
        fontSize: 30,
        fontWeight: 500,
        color: '#9c9c9c',
      }
    },
    itemContainer: {
      width: '80%',
      minHeight: 100,
      margin: '10px auto',
      borderRadius: 20,
      padding: 10,
      border: '1px dashed #666992',
      '& span': {
        width: '100%',
        textAlign: 'center',
        fontSize: 20,
        color: '#d4d4d4',
        marginTop: 20,
      }
    },
    subItem: {
      position: 'relative',
      width: 'calc((100% - 60px)/4)',
      height: 140,
      borderRadius: 20,
      background: '#e4acac',
      boxShadow: '0 10px 10px rgb(0 0 0 / 20%)',
      boxSizing: 'border-box',
      padding: 10,
      '& p': {
        fontSize: 20,
        fontWeight: 500,
        color: '#fff',
        textAlign: 'center'
      }
    },
    titleHeader: {
      width: '80%',
      margin: 'auto',
      padding: 10,
      '& p': {
        margin: 0,
      }
    },
    inputContainer: {
      width: '80%',
      margin: 'auto',
      border: '1px solid #6c8194',
      borderRadius: 5,
      height: 40,
      padding: '10px 20px',
      boxSizing: 'border-box',
      '& input': {
        width: '100%',
        border: 'none',
        outline: 'none',
        fontSize: 14,
        fontWeight: 300,
      },
    },

    textContainer: {
      width: '80%',
      margin: 'auto',
      border: '1px solid #6c8194',
      borderRadius: 5,
      // height: 40,
      padding: '10px 20px',
      boxSizing: 'border-box',
      '& textarea': {
        width: '100%',
        border: 'none',
        outline: 'none',
        fontSize: 14,
        fontWeight: 300,
        resize: 'none',
      },
    },

    delBtn: {
      position: 'absolute',
      top: 10,
      right: 10,
    }
  })
)
