import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import SubmitButton from "../../../Components/elements/SubmitButton";
import { AiOutlineClose } from "react-icons/ai";

interface Props {
  setItems(name: string): void;
  delItems(): void;
  setOpen(state: boolean): void;
}

export default function ItemInputModal({setItems, delItems, setOpen}: Props) {
  const classes = useStyles();
  const [title, setTitle] = useState('');

  const submitItem = () => {
    if (title === '') {
      window.alert("아이템의 이름을 입력해주세요")
      return;
    }
    setItems(title);
    setOpen(false);
  }
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }
  const enterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      submitItem();
    }
  }
  const closeModal = () => {
    delItems();
    setOpen(false);
  }

  return (
    <>
      <Grid className={classes.back} onClick={closeModal}/>
      <Grid className={classes.container}>

        <Grid>
          <p>아이템 이름 입력하여 추가하기</p>
          <Grid className={classes.inputContainer}>
            <input 
              onChange={changeHandler}
              onKeyPress={enterPress}
              placeholder="아이템 이름을 입력해주세요"
            />
          </Grid>
        </Grid>

        <Grid container justify={'center'}>
          <SubmitButton onClick={submitItem}>추가하기</SubmitButton>
        </Grid>

        <AiOutlineClose size="20" className={classes.delIcon} onClick={closeModal}/>

      </Grid>
    </>
  )
}

const useStyles = makeStyles(() => 
  createStyles({
    back:{
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 10,
      background: '#00000063',
    },
    container:{
      position: 'absolute',
      top: 30,
      left: 'calc(50% - 200px)',
      width: 400,
      // height: 200,
      background: '#fff',
      boxShadow: '0 10px 30px rgb(0 0 0 / 20%)',
      padding: 20,
      zIndex: 11,
      boxSizing: 'border-box',
    },
    delIcon: {
      position: 'absolute',
      top: 20,
      right: 20,
    },
    inputContainer: {
      width: '100%',
      margin: 'auto',
      marginBottom: 20,
      border: '1px solid #6c8194',
      borderRadius: 5,
      height: 40,
      padding: '10px 20px',
      boxSizing: 'border-box',
      '& input': {
        width: '100%',
        border: 'none',
        outline: 'none',
        fontSize: 14,
        fontWeight: 300,
      },
    },
  })
)