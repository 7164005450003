import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useRecoilState } from "recoil";
import { confirmFilterState } from "../../../states/ConfirmState";

export default function ConfirmFilter() {
  const classes = useStyle();
  const [filter, setFilter] = useRecoilState(confirmFilterState);

  const changeFilterValue = (e: any) => {
    setFilter(e.target.value);
  }

  return (
    <Grid className={classes.container}>
      <select 
        onChange={changeFilterValue} 
        value={filter}
      >
        <option value={3}>전체 보기</option>
        <option value={0}>요청 대기</option>
        <option value={1}>승인 완료</option>
        <option value={2}>반려</option>
      </select>
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      width: '30%',
      margin: '20px auto',
      '& select': {
        width: '100%',
        height: 40,
        padding: '0 10px',
        outline: 'none',
      }
    }
  })
)