import { atom } from "recoil";
import { InoutTalkItems, InoutTalkMains, InoutTalkSubs, MainTitleData } from "../model/InoutTalk";

export const inoutMainState = atom<MainTitleData[]>({
  key: 'inoutMainState',
  default: []
})

export const inoutSubState = atom<InoutTalkSubs>({
  key: 'inoutSubState',
  default: {} as InoutTalkSubs,
})

export const inoutItemState = atom<InoutTalkItems>({
  key: 'inoutItemState',
  default: {} as InoutTalkItems,
})