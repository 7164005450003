import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useState } from "react";
import { RiArrowLeftSFill, RiArrowRightSFill } from "react-icons/ri";

interface Props {
  state: {
    page: number;
    countPage: number;
  }
  setState(state: number): void;
}

export default function PageContainer({state, setState}: Props) {
  const classes = useStyle();
  const {page, countPage} = state;
  const setPage = setState; 
  let defaultListPages = [1,2,3,4,5,6,7,8,9,10];
  const [currentListPages , setCurrentListPages] = useState(defaultListPages);
  const maxPage = countPage % 50 > 0 ? parseInt(`${countPage / 50}`) + 1 : parseInt(`${countPage / 50}`); 
  const toPrePages = () => {
    if (page > 9) {
      let copiedPages = [...currentListPages];
      for (let i=0;i<10;i++) {
        copiedPages[i] -= 10;
      }
      setCurrentListPages(copiedPages);
      setPage(copiedPages[9] - 1);
    }
  }

  const toNextPages = () => {
    let copiedPages = [...currentListPages];
    if (copiedPages[0] + 9 >= maxPage) return; 
    for (let i=0;i<10;i++) {
      copiedPages[i] += 10;
    }
    setCurrentListPages(copiedPages);
    setPage(copiedPages[0] - 1);
  }

  return (
    <Grid 
      container 
      justifyContent='center' 
      alignItems='center'
    >

      <Grid 
        className={classes.arrowBox} 
        item
        onClick={toPrePages}
      >
        <RiArrowLeftSFill size='25' style={page < 10 ? {color: '#9c9c9c'} : {}}/>
      </Grid>
      
      <Grid 
        className={classes.pageBox} 
        container 
        justifyContent='center'
        alignItems='center' 
        item
      >
        {currentListPages.map((unitPage, index) => {
          if (currentListPages[index] > maxPage) return;
          return (
            <Grid key={index} item onClick={() => setPage(unitPage - 1)}>
              <p style={unitPage === page+1 ? {color: '#000'} : {}}>{unitPage}</p>
            </Grid>
          );
        })}
      </Grid>

      <Grid 
        className={classes.arrowBox} 
        item
        onClick={toNextPages}
      >
        <RiArrowRightSFill size='25' style={currentListPages[0] + 9 >= maxPage ? {color: '#9c9c9c'} : {}}/>
      </Grid>

    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    pageBox: {
      width: '35%',
      columnGap: '7%',
      '& div':{
        cursor: 'pointer',
        '& p': {
          fontSize: 18,
          color: '#9c9c9c'
        }
      }
    },
    arrowBox: {
      cursor: 'pointer',
      padding: '0 20px'
    }
  })
)