import { MemberDetail, MemberList } from '../model/User';
import {get} from '../module/HttpWebClient';

export async function getAdminUserList(page: number): Promise<MemberList> {
  const url = `/admin/member/list/${page}`
  const { data } = await get<MemberList>(url);
  return data;
}

export async function getAdminUserDetail(member_idx: number): Promise<MemberDetail> {
  const url = `/admin/member/detail/${member_idx}`;
  const { data } = await get<MemberDetail>(url);
  return data;
}