import { atom } from "recoil";
import { DashboardDiaryData, DashboardUser } from "../model/Dashboard";

export const dashboardUserState = atom<DashboardUser | undefined>({
  key: 'dashboardUserState',
  default: undefined,
})

export const dashboardDiaryState = atom<DashboardDiaryData | undefined>({
  key: 'dashboardDiaryState',
  default: undefined,
})