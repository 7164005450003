import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { member_join_type } from "../../../model/User";
import { UserDetailState } from "../../../states/UserState";

export default function UserGoalTable() {
  const classes = useStyle();
  const userDetail = useRecoilValue(UserDetailState);


  return (
    <>
      <Grid className={classes.table}>
        <p>몸무게</p>
        <div/>
        <span>{userDetail.weight_goal}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>칼로리</p>
        <div/>
        <span>{userDetail.calories_goal}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>순탄수</p>
        <div/>
        <span>{userDetail.net_carbohydrate_goal}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>단백질</p>
        <div/>
        <span>{userDetail.protein_goal}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>지방</p>
        <div/>
        <span>{userDetail.fat_goal}</span>
      </Grid>
      <div className={classes.line}/>

    </>
    
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    table: {
      width: '90%',
      height: 35,
      margin: 'auto',
      background: '#fff',
      display: 'grid',
      gridTemplateColumns: '17% 0.1% 32% ',
      justifyItems: 'center',
      alignItems: 'center',
      '& p': {
        width: '100%',
        fontSize: 13,
        fontWeight: 500,
        color: '#414141',
        textAlign: 'center',
        padding: '9.5px 0',
        margin: 0,

        background: '#dddddd'
      },
      '& span': {
        fontSize: 12,
        fontWeight: 400,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& div': {
        width: '100%',
        height: 35,
        border: 'none',
        background: '#999999',
      },
    },
    line: {
      width: '90%',
      margin: 'auto',
      paddingRight: '50%',
      boxSizing: 'border-box',
      height: 1,
      background: '#afafaf',
    }
  })
)