import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useSetRecoilState } from "recoil";
import { getAdminConfirmDetail } from "../../../apis/confirm";
import { ConfirmData } from "../../../model/Confirm";
import { confirmDetailState, confirmModalState } from "../../../states/ConfirmState";

interface Props {
  confirm: ConfirmData
}

export default function ConfirmTableEl({confirm}: Props) {
  const classes = useStyle()
  const setOpenModal = useSetRecoilState(confirmModalState);
  const setConfirmDetail = useSetRecoilState(confirmDetailState);

  const {
    edit_type,
    edit_idx,
    edit_type_name,
    edit_status_name,
    member_name,
    food_name,
    created_at} = confirm;

  const openDetail = async () => {
    const data = await getAdminConfirmDetail(edit_idx);
    console.log(data);
    setConfirmDetail(data.results);
    setOpenModal(true);
  }

  return (
    <>
      <Grid className={classes.table} onClick={openDetail}>
        <p>{edit_idx}</p>
        <div/>
        <p>{edit_type_name}</p>
        <div/>
        <p>{edit_status_name}</p>
        <div/>
        <p>{member_name}</p>
        <div/>
        <p>{food_name}</p>
        <div/>
        <p>{created_at}</p>
      </Grid>
      <div className={classes.line}/>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    table: {
      width: '90%',
      height: 25,
      margin: 'auto',
      background: '#fff',
      display: 'grid',
      gridTemplateColumns: '14% 0.1% 12% 0.1% 12% 0.1% 16% 0.1% 24% 0.1% 18%',
      alignItems: 'center',
      cursor: 'pointer',
      '& p': {
        fontSize: 14,
        fontWeight: 500,
        color: '#414141',
        textAlign: 'center',
        margin: 0,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& div': {
        width: 1,
        height: 30,
        border: 'none',
        background: '#999999',
      },
    },
    line: {
      width: '90%',
      margin: '5px auto',
      height: 0.5,
      background: '#afafaf',
    }
  })
)