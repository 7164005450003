import { DashboardDiaryResponse, DashboardUser } from "../model/Dashboard";
import { get } from "../module/HttpWebClient"

export async function getAdminDashboardUser(): Promise<DashboardUser> {
  const url = '/admin/dashboard/user';
  const { data } = await get<DashboardUser>(url);
  return data;
}

export async function getAdminDashboardDiary(): Promise<DashboardDiaryResponse> {
  const url = '/admin/dashboard/diary';
  const { data } = await get<DashboardDiaryResponse>(url);
  return data;
}