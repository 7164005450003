import { atom } from "recoil";
import { FoodDetailData, FoodModalType, FoodNormalData, SortingFilterNumber } from "../model/Food";

export const foodNormalListState = atom<FoodNormalData[]>({
  key: 'foodNormalListState',
  default: []
})

export const foodDetailState = atom<FoodDetailData>({
  key: 'foodDetailState',
  default: {} as FoodDetailData
})

export const foodFilterState = atom<SortingFilterNumber>({
  key: 'foodFilterState',
  default: 0,
})

export const foodModalState = atom<boolean>({
  key: 'foodModalState',
  default: false,
})

export const foodModalTypeState = atom<FoodModalType>({
  key: 'foodModalTypeState',
  default: 0,
})