import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { ArrProduct, StoreProductData } from "../../../model/Store";
import ProductItem from "./ProductItem";
import { AiOutlinePlus } from "react-icons/ai";
import StoreModal from "./StoreModal";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { StoreProductState } from "../../../states/StoreState";
import { getAdminProduct } from "../../../apis/store";


export default function Product() {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [productList, setProductList] = useRecoilState(StoreProductState);

  useEffect(() => {
    (async () => {
      const data = await getAdminProduct();
      setProductList(data);
    })();
  }, [])

  return (
    <Grid className={classes.container}>
      {productList.map((item, index) => {
        return <ProductItem {...item} key={item.product_idx} />
      })}
      <Grid className={classes.createBtn} onClick={()=>setOpen(true)} container justifyContent='center' alignItems='center'>
        <AiOutlinePlus size='24' color='#fff'/>
      </Grid>
      {open && <StoreModal open={open} setOpen={setOpen} _data={productList}/>}
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      position: 'relative',
      width: '90%',
      margin: 'auto',
      padding: '20px 0',
      background: '#fff',
      boxShadow: '0 20px 30px rgb(0 0 0 / 30%)',
    },
    createBtn: {
      position: 'absolute',
      top: 20,
      right: 30,
      width: 50,
      height: 50,
      borderRadius: '50%',
      background: '#376a7e',
      cursor: 'pointer',
    }
  })
)