import { atom } from "recoil";
import { ConfirmData, ConfirmDetailData, SortingFilterNumber } from "../model/Confirm";

export const confirmListState = atom<ConfirmData[]>({
  key: 'confirmListState',
  default: [],
})

export const confirmDetailState = atom<ConfirmDetailData>({
  key: 'confirmDetailState',
  default: {} as ConfirmDetailData
})

export const confirmFilterState = atom<SortingFilterNumber>({
  key: 'confirmFilterState',
  default: 3,
})

export const confirmModalState = atom<boolean>({
  key: 'confirmModalState',
  default: false,
})