import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useSetRecoilState } from "recoil";
import Product from "./components/Product";
import { StoreProductState, StorePromotionState } from "../../states/StoreState";
import { useEffect, useState } from "react";
import { getAdminProduct, getAdminPromotion } from "../../apis/store";
import Header from "../../shared/Header";
import SideNav from "../../shared/SideNav";
import Promotion from "./components/Promotion";
import HeaderEl from "../../Components/HeaderEl";

export default function Store(Props: any) {
  const classes = useStyle();
  const [toggle, setToggle] = useState(true);

  const productStyle = (): any => {
    return toggle ? 
    {left: 'calc(50% - 195px)', zIndex: '10'} 
    : {left: 'calc(50% - 195px)', zIndex: '5', background: '#00000058'}
  }
  const promotionStyle = (): any => {
    return !toggle ? 
    {left: 'calc(50% - 5px)', zIndex: '10'} 
    : {left: 'calc(50% - 5px)', zIndex: '5', background: '#00000058'}
  }

  return (
    <>
      <Header/>
      <SideNav/>
      <HeaderEl title='스토어 관리'/>
      <Grid className={classes.container}>
        <Grid className={classes.content}>
          
          <Grid className={classes.toggleBox}>
            <Grid style={productStyle()} onClick={()=>setToggle(true)} container justifyContent='center' alignItems='center'>
              <p>인기 상품</p>
            </Grid>
            <Grid style={promotionStyle()} onClick={()=>setToggle(false)} container justifyContent='center' alignItems='center'>
              <p>기획전</p>
            </Grid>
          </Grid>
          
          {toggle ? 
          <Product/>
          : <Promotion/>}
        </Grid>
      </Grid>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      padding: '20px 50px',
    },
    header: {
      width: '100%',
      '& p': {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#666',
      }
    },
    content: {
      position: 'relative',
      width: '100%',
    },
    toggleBox: {
      height: 30,
      '& div':{
        position: 'absolute',
        width: 200,
        height: 30,
        background: '#fff',
        borderRadius: '5px 5px 0 0',
        boxShadow: '0 -2px 5px rgb(0 0 0 / 20%)',
        cursor: 'pointer',
        '& p': {
          fontSize: 14,
          fontWeight: 500,
          margin: 0,
        }
      }
    }
 
  })
)