import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { StorePromotionData } from "../../../model/Store";
import moment from "moment";
import { TiDelete } from "react-icons/ti";
import { MdModeEditOutline } from "react-icons/md";
import { delAdminPromotion } from "../../../apis/store";
import { useCallback } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { PromotionEditIdState, PromotionEditState, PromotionModalState, StorePromotionState } from "../../../states/StoreState";


export default function PromotionItem({
  promotion_idx,
  promotion_sort,
  promotion_name,
  promotion_content,
  promotion_url,
  start_at,
  end_at,
  promotion_img,
  show_yn,
}: StorePromotionData) {
  const classes = useStyle();
  const setOpen = useSetRecoilState(PromotionModalState);
  const setEdit = useSetRecoilState(PromotionEditState);
  const setId = useSetRecoilState(PromotionEditIdState);
  const [promotionList, setPromotionList] = useRecoilState(StorePromotionState);

  const end = moment(end_at);
  const start = moment(start_at);
  const today = moment();
  const diff = moment.duration(end.diff(start)).asDays();
  const diffToday = moment.duration(end.diff(today)).asDays();
  const isEnd = diff < 0 || diffToday < 0;

  const delPromotion = async () => {
    if (window.confirm('기획전을 삭제하시겠습니까?')) {
      const msg = await delAdminPromotion(promotion_idx);
      console.log(msg);
      let deletedList = promotionList.filter((item) => item.promotion_idx !== promotion_idx);
      setPromotionList(deletedList);
    }
  }

  const openEditModal = useCallback(() => {
    setOpen(true);
    setEdit(true);
    setId(promotion_idx);
  }, [])

  return (
    <Grid className={classes.container}>
      
      <Grid className={classes.img}>
        <img src={promotion_img} alt={promotion_name}/>
      </Grid>
      
      <Grid className={classes.content} >
        <p>기획전명: {promotion_name}</p>
        <p>내용: {promotion_content}</p>
        <p>링크: {promotion_url}</p>
      </Grid>
      
      <Grid className={classes.date} >
        <p>{start_at}</p>
        <p>{end_at}</p>
      </Grid>
      
      <Grid className={classes.show} container alignItems='center' justifyContent='center'>
        <div style={show_yn === 'Y' ? {background: '#0de22a'} : {background: '#ec1c1c'}}/>
      </Grid>

      <Grid className={classes.sort} container justifyContent='center' alignItems='center'>
        <p>
          {promotion_sort}
        </p>
      </Grid>

      <Grid className={classes.editBtn} onClick={openEditModal} container justifyContent='center' alignItems='center'>
        <MdModeEditOutline size='24' color='#777'/>
      </Grid>

      <Grid className={classes.delBtn} onClick={delPromotion} container justifyContent='center' alignItems='center'>
        <TiDelete size='24' color='#777'/>
      </Grid>

      {isEnd && <Grid className={classes.isEnd} container justifyContent='center' alignItems='center'>
        <p>마감된 기획전입니다</p>
      </Grid>}
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      position: 'relative',
      width: '60%',
      margin: 'auto',
      marginBottom: 15,
      padding: '0 10px 0 30px',
      boxSizing: 'border-box',
      maxHeight: 120,
      background: '#fff',
      boxShadow: '0 10px 20px rgb(0 0 0 / 12%)',
      display: 'grid',
      gridTemplateColumns: '30% 50% 20%',
      gridTemplateRows: '120px',
      alignItems: 'center',
    },
    img: {
      height: '100%',
      boxSizing: 'border-box',
      overflow: 'hidden',
      padding: 10,
      '& img': {
        maxWidth: '100%',
        maxHeight: '70%',
      }
    },
    content: {
      height: '100%',
      width: '100%',
      boxSizing: 'border-box',
      padding: '15px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '& p': {
        fontWeight: 'bold',
        color: '#3b3b3b',
        margin: 'auto',
      }
    },
    date: {
      padding: '40px 10px',
      '& p': {
        fontSize: 14,
        fontWeight: 500,
        textAlign: 'center',
      }
    },
    show: {
      position: 'absolute',
      top: 0,
      right: 60,
      width: 40,
      height: 40,
      '& div': {
        width: 15,
        height: 15,
        borderRadius: '50%',
      }
    },
    sort: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 30,
      height: 30,
      '& p': {
        fontSize: 25,
        fontWeight: 500,
        fontStyle: 'italic',
        textAlign: 'center',
        lineHeight: '1',
        color: '#999fab'
      }
    },
    editBtn: {
      position: 'absolute',
      top: 0,
      right: 30,
      width: 30,
      height: 40,
      cursor: 'pointer',
      zIndex: 1001,
    } ,
    delBtn: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 30,
      height: 40,
      cursor: 'pointer',
      zIndex: 1001,
    },
    isEnd: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: '#0000007b',
      zIndex: 999,
      '& p': {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#fff',
      }
    }
  })
)