import { makeStyles, Grid, createStyles } from "@material-ui/core";
import axios from "axios";
import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getCookie } from "../../../module/Cookie";
import { foodDetailState, foodModalTypeState } from "../../../states/FoodState";
import InputBox from "../elements/InputBox";

export default function FoodFormdataContainer() {
  const classes = useStyle();
  const foodDetail = useRecoilValue(foodDetailState);
  const setTypeModal = useSetRecoilState(foodModalTypeState);
  const [file, setFile] = useState<any>();

  const setImgFile = (e: any) => {
    let imgFile = e.target.files[0];
    setFile(imgFile);
  }

  const formData = new FormData();
  const addImg = async () => {
    formData.append('food_idx', String(foodDetail.food_idx));
    formData.append('food_img', file);
    const token = getCookie('is_login')

    axios({
      method: 'post',
      url: '/admin/food/img',
      data: formData,
      headers: { "Content-Type": "multipart/form-data", adt: token}
    }).then((res) => {
      console.log(res);
      setTypeModal(0);
    }).catch((err) => {
      console.log(err, '이미지등록 실패');
    })
    
  }

  return (
    <>
      <Grid className={classes.input}>
        <InputBox type='file' placeholder='이미지를 등록해주세요' setState={setImgFile} value={''}/>
      </Grid>

      <Grid 
      className={classes.buttonContainer}
      container 
      justifyContent='center' 
      alignItems='center'
      >
        <Grid onClick={addImg}>
          <span >등록하기</span>
        </Grid>
      </Grid>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    input: {
      width: '80%',
      margin: 'auto',
    },
    buttonContainer: {
      width: '50%',
      margin: '20px auto',
      '& div': {
        width: 100,
        height: 30,
        background: '#67a56f',
        borderRadius: 5,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
          fontSize: 14,
          color: '#fff',
        }
      }
    }
  })
)