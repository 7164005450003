import { makeStyles, Grid, createStyles } from "@material-ui/core";
import InputBox from "../../Components/elements/InputBox";
import LoginBtn from "./elements/LoninBtn";
import { login } from "../../apis/login";
import { useEffect, useState } from "react";
import { LoginRequestData } from "../../model/Login";
import { getCookie } from "../../module/Cookie";


export default function Login(Props: any) {
  const classes = useStyle();
  const history = Props.history;
  const [id, setId] = useState<string | undefined>();
  const [pw, setPw] = useState<string | undefined>();
  const token = getCookie('is_login');

  useEffect(() => {
    if (token) {
      history.replace('/');
    }
  }, [])

  const adminLogin = async () => {
    const msg = await login({ id: id, pw: pw });
    history.replace('/');
  };

  return (
    <Grid className={classes.container}>
      <Grid className={classes.inputBox}>
        <InputBox type='text' placeholder='아이디를 입력해주세요' setState={setId}/>
      </Grid>
      <Grid className={classes.inputBox}>
        <InputBox type='password' placeholder='비밀번호를 입력해주세요' setState={setPw}/>
      </Grid>
      <Grid>
        <LoginBtn text='로그인' onClick={adminLogin}/>
      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      width: '30%',
      marginTop: 200,
      margin: 'auto',
      border: '1px solid black',
      borderRadius: 20,
      padding: '50px 50px',
    },
    inputBox: {
      marginBottom: 30,
    },
  })
)