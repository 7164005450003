import { makeStyles, Grid, createStyles } from "@material-ui/core";
import HeaderEl from "../../Components/HeaderEl";
import Header from "../../shared/Header";
import SideNav from "../../shared/SideNav";
import DashboardDiary from "./components/DashboardDiary";
import DashboardUser from "./components/DashboardUser";


export default function DashBorad(Props: any) {
  const classes = useStyle();

  return (
    <>
      <Header/>
      <SideNav history={Props.history}/>
      <HeaderEl title='대시보드'/>
      <Grid
        className={classes.container}
      >
        <DashboardUser/>
        <DashboardDiary/>
      </Grid>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      width: '70%',
      margin: 'auto',
    }
  })
)