import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { foodDetailState } from "../../../states/FoodState";

export default function FoodDetailIngredientTable() {
  const classes = useStyle()
  const foodDetailData = useRecoilValue(foodDetailState);

  return (
    <>
      <div className={classes.line}/>
      <Grid className={classes.table}>
        <p>칼로리</p>
        <div/>
        <span>{foodDetailData.calories}</span>
        <div/>
        <p>탄수화물</p>
        <div/>
        <span>{foodDetailData.carbohydrate}</span>
        <div/>
        <p>순 탄수</p>
        <div/>
        <span>{foodDetailData.net_carbohydrate}</span>
        <div/>
        <p>식이섬유</p>
        <div/>
        <span>{foodDetailData.fiber}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>당</p>
        <div/>
        <span>{foodDetailData.sugar}</span>
        <div/>
        <p>대체당</p>
        <div/>
        <span>{foodDetailData.sugar_sub}</span>
        <div/>
        <p>단백질</p>
        <div/>
        <span>{foodDetailData.protein}</span>
        <div/>
        <p>나트륨</p>
        <div/>
        <span>{foodDetailData.sodium}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>지방</p>
        <div/>
        <span>{foodDetailData.fat}</span>
        <div/>
        <p>포화 지방</p>
        <div/>
        <span>{foodDetailData.saturated_fat}</span>
        <div/>
        <p>불포화 지방</p>
        <div/>
        <span>{foodDetailData.monounsaturated_fat}</span>
        <div/>
        <p>다포화 지방</p>
        <div/>
        <span>{foodDetailData.polyunsaturated_fat}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>트랜스 지방</p>
        <div/>
        <span>{foodDetailData.trans_fat}</span>
        <div/>
        <p>콜레스테롤</p>
        <div/>
        <span>{foodDetailData.cholesterol}</span>
        <div/>
        <p>칼륨</p>
        <div/>
        <span>{foodDetailData.potassium}</span>
        <div/>
        <p></p>
        <div/>
        <span></span>
      </Grid>
      <div className={classes.line}/>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    table: {
      width: '90%',
      height: 35,
      margin: 'auto',
      background: '#fff',
      display: 'grid',
      gridTemplateColumns: '12.4% 0.1% 12.4% 0.1% 12.4% 0.1% 12.4% 0.1% 12.4% 0.1% 12.4% 0.1% 12.4% 0.1% 12.4%',
      justifyItems: 'center',
      alignItems: 'center',
      '& p': {
        width: '100%',
        fontSize: 13,
        fontWeight: 500,
        color: '#414141',
        textAlign: 'center',
        padding: '9.5px 0',
        margin: 0,
        background: '#dddddd'
      },
      '& div': {
        width: '100%',
        height: 35,
        border: 'none',
        background: '#999999',
      },
      '& span': {
        fontSize: 12,
        fontWeight: 400,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }
    },
    line: {
      width: '90%',
      margin: 'auto',
      height: 1,
      background: '#afafaf',
    }
  })
)