import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { getAdminConfirmList } from "../../../apis/confirm";
import PageContainer from "../../../Components/PageContainer";
import { confirmFilterState, confirmListState, confirmModalState } from "../../../states/ConfirmState";
import ConfirmTableEl from "../elements/ConfirmTableEl";
import ConfirmFilter from "../elements/ConfirmFilter";
import ConfirmModal from "./ConfirmModal";

export default function ConfirmList() {
  const classes = useStyle();
  const [page, setPage] = useState(0);
  const filter = useRecoilValue(confirmFilterState);
  const [confirmList, setConfirmList] = useRecoilState(confirmListState);
  const openModal = useRecoilValue(confirmModalState);
  const [countPage, setCountPage] = useState(1);

  useEffect(() => {
    (async () => {
      const data = await getAdminConfirmList(page, filter);
      console.log(data);
      setConfirmList(data.results);
      setCountPage(data.countForPage)
    })();
  }, [page, filter]);

  return (
    <Grid className={classes.container}>
      <ConfirmFilter/>
      <Grid className={classes.table}>
        <p>No</p>
        <div/>
        <p>타입</p>
        <div/>
        <p>상태</p>
        <div/>
        <p>회원 이름</p>
        <div/>
        <p>음식 이름</p>
        <div/>
        <p>작성 일자</p>
      </Grid>
      <div className={classes.line}/>
      {confirmList.map((confirm, index) => {
        return <ConfirmTableEl confirm={{...confirm}} key={confirm.edit_idx}/>;
      })}

      <PageContainer state={{page, countPage}} setState={setPage}/>
      {openModal && <ConfirmModal/>}
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      position: 'relative',
      width: '90%',
      margin: '20px auto',
      padding: '20px 20px',
      background: '#fff',
      boxShadow: '0 10px 30px rgb(0 0 0 / 20%)',
    },
    table: {
      width: '90%',
      height: 25,
      margin: 'auto',
      background: '#dddddd',
      display: 'grid',
      gridTemplateColumns: '14% 0.1% 12% 0.1% 12% 0.1% 16% 0.1% 24% 0.1% 18%',
      alignItems: 'center',
      cursor: 'pointer',
      '& p': {
        fontSize: 14,
        fontWeight: 500,
        color: '#414141',
        textAlign: 'center',
        margin: 0,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& div': {
        width: 1,
        height: 30,
        border: 'none',
        background: '#999999',
      },
    },
    line: {
      width: '90%',
      margin: 'auto',
      height: 0.5,
      background: '#afafaf',
    }
  })
)