import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import axios from "axios";
import { getCookie } from "../../../module/Cookie";
import StorePromotionForm from "../elements/StorePromotionForm";
import { useRecoilState, useRecoilValue } from "recoil";
import { PromotionEditIdState, PromotionEditState, PromotionModalState, StorePromotionState } from "../../../states/StoreState";



export default function StorePromotionModal() {
  const classes = useStyle();
  const [open, setOpen] = useRecoilState(PromotionModalState);
  const [isEdit, setEdit] = useRecoilState(PromotionEditState);
  const id = useRecoilValue(PromotionEditIdState);
  const [promotionList, setPromotionList] = useRecoilState(StorePromotionState);

  const promotionData = {
    promotion_name: '',
    promotion_content: '',
    promotion_url: '',
    start_at: '',
    end_at: '',
    promotion_img: '',
  }

  const [promotion, setPromotion] = useState<any>(promotionData);
  const [file, setFile] = useState<any>();

  const formData = new FormData();
  const setPromotionFile = (e: any) => {
    let imgFile = e.target.files[0];
    setFile(imgFile)
  }

  const createProduct = () => {
    formData.append('promotion_name', promotion.promotion_name);
    formData.append('promotion_content', promotion.promotion_content);
    formData.append('promotion_url', promotion.promotion_url);
    formData.append('start_at', promotion.start_at);
    formData.append('end_at', promotion.end_at);
    formData.append('promotion_img', file);
    const token = getCookie('is_login');

    !isEdit ?
    axios({
      method: 'post',
      url: '/admin/store/promotion',
      data: formData,
      headers: { "Content-Type": `multipart/form-data`, adt: token}
    }).then((res) => {
      // 데이터 요청 필요
      // let newData = {
      //   promotion_idx: 1,
      //   promotion_sort: 2,
      //   promotion_name: 'string',
      //   promotion_content: 'string',
      //   promotion_url: 'string',
      //   start_at: 'string',
      //   end_at: 'string',
      //   promotion_img: 'string',
      //   show_yn: 'string',
      // }
      // const newList = [newData, ...promotionList];
      // setPromotionList(newList);
      console.log(res)
      setOpen(false);
    }).catch((err) => {
      console.log(err,'err')
    })
    :
    axios({
      method: 'patch',
      url: `/admin/store/promotion/${id}`,
      data: formData,
      headers: { "Content-Type": 'multipart/form-data', adt: token}
    }).then((res) => {
      // 데이터 요청 필요
      // console.log(res)
      // let editedData = {
      //   promotion_idx: 1,
      //   promotion_sort: 2,
      //   promotion_name: 'string',
      //   promotion_content: 'string',
      //   promotion_url: 'string',
      //   start_at: 'string',
      //   end_at: 'string',
      //   promotion_img: 'string',
      //   show_yn: 'string',
      // }
      // const copyList = [...promotionList];
      // let editedIndex = promotionList.findIndex((promotion) => promotion.promotion_idx === id);
      // copyList[editedIndex] = editedData;
      // setPromotionList(copyList);
      setEdit(false);
      setOpen(false);
    }).catch((err) => {
      console.log(err,'err')
    })
    
  }

  const exitModal = () => {
    setEdit(false);

    setOpen(false);
  }

  return (
    <Grid className={classes.background}>
      <Grid className={classes.container}>
        
        <Grid className={classes.header} container justifyContent='space-between' alignItems='center'>
          <p>{isEdit ? '수정 하기!' : '등록 하기!'}</p>
          <Grid onClick={exitModal}>
            <AiOutlineClose size='20'/>
          </Grid>
        </Grid>

        <Grid container alignItems='center'>
          <StorePromotionForm label={isEdit? '기획전 수정' : '기획전 등록'} data={promotion} setData={setPromotion} onChange={setPromotionFile}/>
        </Grid>

        <Grid>
          <Grid className={classes.btnContainer} onClick={createProduct} container justifyContent='center' alignItems='center'>
            <span>{isEdit ? '수정' : '등록'}</span>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    background: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: '#00000067',
      zIndex: 100,
    },
    container: {
      position: 'relative',
      width: '60%',
      background: '#fff',
      boxShadow: '0 20px 30px rgb(0 0 0 / 30%)',
      margin: '50px auto 0 auto',
      zIndex: 101,
      padding: '10px 20px',
    },
    header: {
      padding: '0 10px',
      '& p': {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#666',
      },
      '& div': {
        cursor: 'pointer',
      }
    },
    btnContainer: {
      width: 100,
      height: 40,
      margin: '10px auto',
      background: '#376a7e',
      borderRadius: 10,
      cursor: 'pointer',
      '& span': {
        fontSize: 14,
        color: '#fff',
      }
    }
  })
)