import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { foodModalTypeState } from "../../../states/FoodState";

interface Props {
  type: string;
  placeholder: string;
  setState: any;
  value: any;
}

export default function InputBox({type, placeholder, setState, value}: Props) {
  const classes = useStyle();
  const typeModal = useRecoilValue(foodModalTypeState);

  const splitWord = () => {
    if (placeholder.includes('을')) {
      return placeholder.split('을')[0];
    } else {
      return placeholder.split('를')[0];
    }
  }

  return (
    <>
      <span className={classes.label}>{splitWord()}</span>
      <Grid className={classes.container}>
        {typeModal === 2 ? 
          <input type={type} placeholder={placeholder} onChange={setState} value={value} />
        :
          <input type={type} placeholder={placeholder} onChange={setState} />
        }
      </Grid>
    </>
  )
}

const useStyle = makeStyles(() => 
  createStyles({
    label:{
      width: '100%',
      fontSize: 13,
      color: '#666666',
      marginLeft: 10,
    },
    container: {
      width: '100%',
      height: 30,
      border: '1px solid #74759c',
      borderRadius: 5,
      padding: '4px 15px',
      boxSizing: 'border-box',
      marginBottom: 10,
      '& input': {
        width: '100%',
        border: 'none',
        outline: 'none',
        fontSize: 14,
      }
    },
  })
)