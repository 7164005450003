import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import HeaderEl from "../../Components/HeaderEl";
import Header from "../../shared/Header";
import SideNav from "../../shared/SideNav";
import { UserModalState } from "../../states/UserState";
import UserDetailModal from "./components/UserDetailModal";
import UserList from "./components/UserList";

export default function Users(Props: any) {
  const classes = useStyle();
  const openModal = useRecoilValue(UserModalState);

  return (
    <>
      <Header/>
      <SideNav/>
      <HeaderEl title='회원 관리'/>
      <Grid className={classes.container}>
        <Grid className={classes.content}>
          <Grid className={classes.title}>
            <p>회원 목록</p>
          </Grid>
          <UserList/>
          {openModal && <UserDetailModal/>}
        </Grid>
      </Grid>
      
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      padding: '20px 50px',
    },
    header: {
      width: '100%',
      '& p': {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#666',
      },
    },
    title: {
      width: '80%',
      margin: '20px auto 0 auto',
      '& p': {
        fontSize: 18,
        color: '#535353',
        margin: 0,
      }
    },
    content: {
      position: 'relative',
      width: '90%',
      // height: 600,
      margin: 'auto',
      padding: '20px 20px',
      background: '#fff',
      boxShadow: '0 20px 30px rgb(0 0 0 / 30%)',
    }
  })
)