import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { foodDetailState } from "../../../states/FoodState";

export default function FoodDetailInfoTable() {
  const classes = useStyle()
  const foodDetailData = useRecoilValue(foodDetailState);

  return (
    <>
      <div className={classes.line}/>
      <Grid className={classes.table}>
        <p>음식 타입</p>
        <div/>
        <span>{foodDetailData.food_type}</span>
        <div/>
        <p>브랜드</p>
        <div/>
        <span>{foodDetailData.food_brand}</span>
        <div/>
        <p>평점</p>
        <div/>
        <span>{foodDetailData.grade}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>1회 제공 단위</p>
        <div/>
        <span>{foodDetailData.metric_serving_unit} {foodDetailData.measurement_description}</span>
        <div/>
        <p>1회 제공량</p>
        <div/>
        <span>{foodDetailData.metric_serving_amount} {foodDetailData.metric_serving_unit}</span>
        <div/>
        <p>리뷰 수</p>
        <div/>
        <span>{foodDetailData.review_count}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>생성자</p>
        <div/>
        <span>{foodDetailData.creator}</span>
        <div/>
        <p>생성자 idx</p>
        <div/>
        <span>{foodDetailData.creator_member_idx}</span>
        <div/>
        <p>생성일자</p>
        <div/>
        <span>{foodDetailData.created_at.split('T')[0]}</span>
      </Grid>
      <div className={classes.line}/>

    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    table: {
      width: '90%',
      height: 35,
      margin: 'auto',
      background: '#fff',
      display: 'grid',
      gridTemplateColumns: '16% 0.1% 16% 0.1% 16% 0.1% 16% 0.1% 16% 0.1% 16%',
      justifyItems: 'center',
      alignItems: 'center',
      '& p': {
        width: '100%',
        fontSize: 13,
        fontWeight: 500,
        color: '#414141',
        textAlign: 'center',
        padding: '9.5px 0',
        margin: 0,
        background: '#dddddd'
      },
      '& div': {
        width: '100%',
        height: 35,
        border: 'none',
        background: '#999999',
      },
      '& span': {
        fontSize: 12,
        fontWeight: 400,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }
    },
    line: {
      width: '90%',
      margin: 'auto',
      height: 1,
      background: '#afafaf',
    }
  })
)