import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { TiDelete } from "react-icons/ti";
import { useRecoilState } from "recoil";
import { delAdminInoutTalkMain, getAdminInoutTalkSub } from "../../../apis/inoutTalk";
import { MainTitleData, SubTitleData } from "../../../model/InoutTalk";
import { inoutMainState, inoutSubState } from "../../../states/InoutTalkState";
import SubTalk from "./SubTalk";

export default function MainTalk({main_title_idx, main_title_name}: MainTitleData) {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [subData, setSubData] = useState<SubTitleData[]>([]);
  const [mainList, setMainList] = useRecoilState(inoutMainState);
  
  useEffect(() => {
    (async () => {
      const data = await getAdminInoutTalkSub(main_title_idx);
      setSubData(data.sub_title);
    })();
  }, [])

  const list = subData;

  if(!list) return <></>; 

  const delMain = async () => {
    if (window.confirm('삭제하시겠습니까?')){
      const msg = await delAdminInoutTalkMain(main_title_idx);
      console.log(msg);      
      let deletedList = mainList.filter((item) => item.main_title_idx !== main_title_idx);
      setMainList(deletedList);
    }
  }


  const onOff = (): void => {
    open ? setOpen(false) : setOpen(true);
  }

  return (
    <>
      <Grid className={classes.card} container onClick={onOff}>
        <Grid className={classes.cardHeader} item style={{background: '#376a7e'}} container alignItems='center' justifyContent='center'>
          <p>메인</p>
        </Grid>
        <Grid className={classes.contents} container alignItems='center' item>
          <p>{main_title_idx}</p>
          <p>{main_title_name}</p>
        </Grid>
        <Grid className={classes.delBtn} onClick={delMain} container item justifyContent='center' alignItems='center'>
          <TiDelete size='22' color='#777'/>
        </Grid>
      </Grid>
      <Grid className={classes.subContainer}>
        {open && list.map((sub, index) => {
          return <SubTalk subUseState={{subData, setSubData}} subTitleData={{...sub}} key={index}/>
        })}
      </Grid>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    card: {
      height: 70,
      width: '80%',
      margin: 'auto',
      background: '#fff',
      boxShadow: '0 2px 10px rgb(0 0 0 / 20%)',
      marginBottom: 10,
      cursor: 'pointer',
    },
    cardHeader: {
      width: 70,
      height: '100%',
      '& p': {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#fff',
      },
    },
    contents: {
      padding: '0 25px',
      width: 'calc(100% - 140px)',
      '& p': {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#888',
        marginRight: 25,
      }
    },
    delBtn: {
      height: 70,
      width: 70,
      cursor: 'pointer',
    },
    subContainer: {
      boxSizing: 'border-box',
      width: '95%',
      margin: 'auto',
      // paddingLeft: 50,
    }
  })
)