import { makeStyles, Grid, createStyles } from "@material-ui/core";
import HeaderEl from "../../Components/HeaderEl";
import Header from "../../shared/Header";
import SideNav from "../../shared/SideNav";
import FoodSearchContainer from "./components/FoodSearchContainer";
import FoodSearchResult from "./components/FoodSearchResult";

export default function Foods(Props: any) {
  const classes = useStyle();

  return (
    <>
      <Header/>
      <SideNav/>
      <HeaderEl title='음식 관리'/>
      <Grid className={classes.container}>
        <FoodSearchContainer/>
        <FoodSearchResult/>          
      </Grid>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      padding: '20px 50px',
    },
  })
)