import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { getAdminUserList } from "../../../apis/user";
import PageContainer from "../../../Components/PageContainer";
import { UserState } from "../../../states/UserState";
import UserTableEl from "../elements/UserTableEl";

export default function UserList() {
  const classes = useStyle();
  const [userList, setUserList] = useRecoilState(UserState);
  const [page, setPage] = useState(0);
  const [countPage, setCountPage] = useState(1);
  useEffect(() => {
    ( async () => {
      const data = await getAdminUserList(page);
      console.log(data);
      if (data) {
        setUserList(data.results);
        setCountPage(data.countForPage);
      }
    })();
  }, [page])

  return (
    <Grid className={classes.container}>
      <Grid className={classes.table}>
        <p>회원 ID</p>
        <div/>
        <p>이름</p>
        <div/>
        <p>전화번호</p>
        <div/>
        <p>이메일</p>
        <div/>
        <p>가입 경로</p>
      </Grid>
      {userList.map((user, index) => {
        return <UserTableEl member={{...user}} key={index}/>
      })}

      <PageContainer state={{page, countPage}} setState={setPage}/>
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      padding: 20,
    },
    table: {
      width: '90%',
      margin: 'auto',
      background: '#dddddd',
      display: 'grid',
      gridTemplateColumns: '17% 1% 17% 1% 24% 1% 24% 1% 14%',
      alignItems: 'center',
      '& p': {
        fontSize: 14,
        fontWeight: 500,
        color: '#414141',
        textAlign: 'center',
        margin: 0,
      },
      '& div': {
        width: 1,
        height: 30,
        border: 'none',
        background: '#999999',
      },
    },

  })
)