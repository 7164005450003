import {del, get, patch, post} from '../module/HttpWebClient';
import { LoginRequestData, LoginResponseData } from '../model/Login';
import { ACCESS_TOKEN } from '../shared/link';
import { setCookie } from '../module/Cookie';

export async function login(request: LoginRequestData) {
  const url = '/admin/auth/login';
  const { data } = await post<LoginResponseData>(url, {
    ...request,
  });
  setCookie(ACCESS_TOKEN, data.token);
}
