import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { AiOutlineClose } from "react-icons/ai";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { foodDetailState, foodModalState, foodModalTypeState, foodNormalListState } from "../../../states/FoodState";
import FoodDetailContainer from "./FoodDetailContainer";
import FoodFormdataContainer from "./FoodFormdataContainer";
import FoodInputContainer from "./FoodInputContainer";

export default function FoodModal() {
  const classes = useStyle();
  const setOpenModal = useSetRecoilState(foodModalState);
  const [typeModal, setTypeModal] = useRecoilState(foodModalTypeState);
  
  const closeModal = () => {
    setOpenModal(false);
    setTypeModal(0);
  }

  const title = () => {
    switch (typeModal) {
      case 0:
        return {
          title: '음식 상세정보',
          component: <FoodDetailContainer/>
        };
      case 1:
        return {
          title: '음식 등록하기',
          component: <FoodInputContainer/>
        };
      case 2:
        return {
          title: '음식 수정하기',
          component: <FoodInputContainer/>
        };
      case 3:
        return {
          title: '이미지 등록하기',
          component: <FoodFormdataContainer/>
        };
    }
  }

  return (
    <Grid className={classes.background}>
      <Grid className={classes.container}>
        
        <Grid 
          className={classes.header} 
          container 
          justifyContent='space-between' 
          alignItems='center'
        >
          <p>{title().title}</p>
          <Grid onClick={closeModal}>
            <AiOutlineClose size='20'/>
          </Grid>
        </Grid>

        {title().component}

      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    background: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: '#00000067',
      zIndex: 100,
    },
    container: {
      position: 'relative',
      width: '80%',
      background: '#fff',
      boxShadow: '0 20px 30px rgb(0 0 0 / 30%)',
      margin: '50px auto 0 auto',
      zIndex: 101,
      padding: '40px 20px',
    },
    header: {
      width: '90%',
      margin: 'auto',
      padding: '0 10px',
      '& p': {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#666',
      },
      '& div': {
        cursor: 'pointer',
      }
    },
  })
)