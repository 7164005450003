import { makeStyles,Grid, createStyles } from "@material-ui/core";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { delAdminFood } from "../../../apis/food";
import { foodDetailState, foodModalState, foodModalTypeState, foodNormalListState } from "../../../states/FoodState";
import FoodDetailHeader from "../elements/FoodDetailHeader";
import FoodDetailInfoTable from "../elements/FoodDetailInfoTable";
import FoodDetailIngredientTable from "../elements/FoodDetailIngredientTable";

export default function FoodDetailContainer() {
  const classes = useStyle();
  const setOpenModal = useSetRecoilState(foodModalState);
  const setTypeModal = useSetRecoilState(foodModalTypeState);
  const foodDetail = useRecoilValue(foodDetailState);
  const [foodNormalList, setFoodNormalList] = useRecoilState(foodNormalListState);
  
  const closeModal = () => {
    setOpenModal(false);
    setTypeModal(0);
  }

  const editFood = () => {
    setTypeModal(2);
  }

  const delFood = async () => {
    if (window.confirm('음식을 삭제하시겠습니까?')) {
      const data = await delAdminFood(foodDetail.food_idx);
      console.log(data);
      setOpenModal(false);
      let copiedList = [...foodNormalList];
      let deletedList = copiedList.filter(food => food.food_idx !== foodDetail.food_idx);
      setFoodNormalList(deletedList);
    }
  }

  const addFoodImg = async () => {
    setTypeModal(3);
  }


  return (
    <>
      <Grid>
        <FoodDetailHeader/>
      </Grid>

      <Grid className={classes.header}>
        <p>음식정보</p>
      </Grid>

      <Grid>
        <FoodDetailInfoTable/>
      </Grid>

      <Grid className={classes.header}>
        <p>영양성분</p>
      </Grid>

      <Grid>
        <FoodDetailIngredientTable/>
      </Grid>

      <Grid 
        className={classes.buttonContainer}
        container 
        justifyContent='space-between' 
        alignItems='center'
      >
        <Grid>
          <span onClick={addFoodImg} >사진등록</span>
        </Grid>

        <Grid>
          <span onClick={editFood} >수정하기</span>
        </Grid>

        <Grid>
          <span onClick={delFood} >삭제하기</span>
        </Grid>

        <Grid onClick={closeModal} style={{background: '#b37171'}}>
          <span>닫기</span>
        </Grid>
      </Grid>
  </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    header: {
      width: '90%',
      margin: 'auto',
      padding: '0 10px',
      '& p': {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#666',
      },
      '& div': {
        cursor: 'pointer',
      }
    },
    buttonContainer: {
      width: '50%',
      margin: '20px auto',
      '& div': {
        width: 100,
        height: 30,
        background: '#67a56f',
        borderRadius: 5,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
          fontSize: 14,
          color: '#fff',
        }
      }
    }
  })
)