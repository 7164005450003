import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { TiDelete } from "react-icons/ti";
import { useRecoilState } from "recoil";
import { delAdminInoutTalkSub, getAdminInoutTalkItem } from "../../../apis/inoutTalk";
import { ItemData, SubTitleData } from "../../../model/InoutTalk";
import { inoutItemState } from "../../../states/InoutTalkState";
import TalkItem from "./TalkItem";

interface Props {
  subTitleData: SubTitleData;
  subUseState: any
}

export default function SubTalk({subTitleData, subUseState}: Props) {
  const classes = useStyle();
  const {sub_title_idx, sub_title_name, content} = subTitleData;
  const {subData, setSubData} = subUseState;
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState<ItemData[]>([]);

  useEffect(() => {
    (async () => {
      const data = await getAdminInoutTalkItem(sub_title_idx);
      setItem(data.inoutTalkItems);
    })();
  }, [])

  if(!item) return <></>;

  const delSub = async () => {
    if (window.confirm('삭제하시겠습니까?')){
      const msg = await delAdminInoutTalkSub(sub_title_idx);
      console.log(msg);
      let deletedList = subData.filter((item: any) => item.sub_title_idx !== sub_title_idx);
      setSubData(deletedList);
    }
  }

  const onOff = (): void => {
    open ? setOpen(false) : setOpen(true);
  }

  return (
    <>
      <Grid className={classes.card} container alignItems='center' onClick={onOff}>
        <Grid className={classes.cardHeader} item container alignItems='center' justifyContent='center'>
          <p>서브</p>
        </Grid>
        <Grid className={classes.contents} item alignItems='center'>
          <p>{sub_title_idx}</p>
          <p>{sub_title_name}</p>
          <p>{content}</p>
        </Grid>
        <Grid className={classes.delBtn} onClick={delSub} container item justifyContent='center' alignItems='center'>
          <TiDelete size='22' color='#777'/>
        </Grid>
      </Grid>
      <Grid className={classes.itemContainer}>
        {open && item.map((itemDetail, index) => {
          return <TalkItem itemData={{...itemDetail}} itemUseState={{item, setItem}} key={index}/>
        })}
      </Grid>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    card: {
      height: 60,
      width: '80%',
      margin: 'auto',
      background: '#fff',
      boxShadow: '0 2px 10px rgb(0 0 0 / 20%)',
      marginBottom: 10,
      cursor: 'pointer',
    },
    cardHeader: {
      width: 60,
      height: '100%',
      background: '#999',
      '& p': {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#fff',
      },
    },
    contents: {
      padding: '0 25px',
      width: 'calc(100% - 120px)',
      display: 'grid',
      gridTemplateColumns: '30px 120px 1fr',
      '& p': {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#888',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        padding: '0 5px',
        margin: 0,
      }
    },
    delBtn: {
      height: 60,
      width: 60,
      cursor: 'pointer',
    },
    itemContainer: {
      boxSizing: 'border-box',
      width: '95%',
      margin: 'auto',
      // paddingLeft: 50,
    }
  })
)