import { makeStyles, createStyles, Grid } from "@material-ui/core";
import { useState, KeyboardEvent, ChangeEvent, useEffect } from "react";
import { useRecoilState } from "recoil";
import { getAdminSearchFoodList } from "../../../apis/food";
import { FoodNormalData } from "../../../model/Food";
import { foodNormalListState } from "../../../states/FoodState";
import { AiOutlineClose } from "react-icons/ai";
import { SubTitleRequest } from "../../../model/InoutTalk";
import ItemInputModal from "./ItemInputModal";

interface Props {
  items: SubTitleRequest;
  setItems(state: SubTitleRequest): void;
}

export default function SearchContainer(subState: Props) {
  const classes = useStyles();
  const {items, setItems} = subState;
  const [word, setWord] = useState('');
  const [foodData, setFoodData] = useRecoilState(foodNormalListState);
  const [openForm, setOpenForm] = useState(false);

  const searchFood = async () => {
    const data = await getAdminSearchFoodList(word);
    if (data) {
      setFoodData(data.results);
    }
  } 

  const searchByEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchFood();
    }
  }

  const changeWord = (e: ChangeEvent<HTMLInputElement>) => {
    setWord(e.target.value);
  }


  const selectItems = (id: number, idx: number) => {

    setItems({
      ...items, 
      food_idx: [...items.food_idx, idx],
      food_id: [...items.food_id, id],
    })
    setOpenForm(true);
  }

  const unselectItems = () => {
    setItems({
      ...items,
      food_id: [...items.food_id.slice(0,-1)],
      food_idx:[...items.food_idx.slice(0,-1)],
    })
  }

  const confirmItems = (name: string) => {
    setItems({
      ...items,
      item_names: [...items.item_names, name],
    })
  }

  useEffect(() => {
    return() => {
      setFoodData([]);
    }
  }, [])


  return (
      <Grid className={classes.container}>

        <Grid className={classes.inputContainer}>
          <input 
            onChange={changeWord}
            onKeyPress={searchByEnter}  
            placeholder="찾으시는 음식을 검색해주세요"
          />
        </Grid>

        <Grid className={classes.searchResult}>
          {foodData.map((item) => {
            return <SearchItem key={item.food_id} state={item} selectItems={selectItems}/>
          })}
          {foodData.length === 0 && 
            <Grid className={classes.searchResult}>
              <span>검색 결과가 없어요</span>
            </Grid>
          }
        </Grid>

        {openForm && 
          <ItemInputModal 
            setItems={confirmItems} 
            delItems={unselectItems}
            setOpen={setOpenForm}
          />
        }

      </Grid>
  )
}

interface ItemProps {
  state: FoodNormalData;
  selectItems(id: number, idx: number, name: string): void;
}

export function SearchItem({state, selectItems}: ItemProps) {
  const classes = useStyles();
  const {food_id, food_idx, food_img, food_name} = state;
  
  return (
    <Grid 
      className={classes.searchItem}
      container 
      justify={'space-between'} 
      alignItems={'center'}
      onClick={()=>selectItems(food_id, food_idx, food_name)}
    >
      {food_img !== null ?
        <img src={food_img} alt="음식 이미지"/>
      :
        <div>이미지가<br/>없어요</div>
      }
      <p>{food_name}</p>
      <p>{food_id}</p>
    </Grid>
  )
}

const useStyles = makeStyles(() => 
  createStyles({

    container: {
      position: 'relative',
      width: '80%',
      margin: 'auto',
      padding: 20,
      boxSizing: 'border-box',
      background: '#f6f6f8',
    },

    inputContainer: {
      width: '40%',
      background: '#fff',
      // margin: 'auto',
      marginBottom: 10,
      border: '1px solid #6c8194',
      borderRadius: 5,
      height: 30,
      padding: '5px 20px',
      boxSizing: 'border-box',
      '& input': {
        width: '100%',
        border: 'none',
        outline: 'none',
        fontSize: 14,
        fontWeight: 300,
      },
    },

    searchResult: {
      width: '100%',
      margin: 'auto',
      background: '#fff',
      '& span': {
        display: 'block',
        width: '100%',
        textAlign: 'center',
        padding: 50,
        boxSizing: 'border-box',
        color: '#494949',
      }
    },

    searchItem: {
      padding: '10px 20px',
      boxSizing: 'border-box',
      minHeight: 70,
      '& img': {
        width: '20%',
        objectFit: 'cover',
      },
      '& div': {
        width: '20%',
        height: 50,
        background: '#bbbbbb',
        textAlign: 'center',
        paddingTop: 5,
        boxSizing: 'border-box',
      }
    },
    
  })
)