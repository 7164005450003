import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { delAdminConfirm, setAdminConfirm } from "../../../apis/confirm";
import { confirmDetailState, confirmListState, confirmModalState } from "../../../states/ConfirmState";
import ConfirmDetailInfoTable from "../elements/ConfirmDetailInfoTable";
import ConfirmDetailIngredientTable from "../elements/ConfirmDetailIngredientTable";

export default function ConfirmModal() {
  const classes = useStyle();
  const setOpenModal = useSetRecoilState(confirmModalState);
  const confirmDetailData = useRecoilValue(confirmDetailState);
  const [confirmList, setConfirmList] = useRecoilState(confirmListState);
  const [comment, setComment] = useState('');

  const commentHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  }

  const closeModal = () => {
    setOpenModal(false);
  }

  const confirmData = async () => {
    if (confirmDetailData.edit_status === 1) return window.alert('이미 승인되었습니다');
    if (confirmDetailData.edit_status === 2) return window.alert('이미 반려되었습니다');
    
    if (window.confirm('유저등록 데이터를 승인하시겠습니까?')) {

      let request = {
        edit_type: confirmDetailData.edit_type,
        edit_idx: confirmDetailData.edit_idx,
      }
      const data = await setAdminConfirm(request);
      console.log(data);
      setOpenModal(false);
    }
  }

  const rejectData = async () => {
    if (confirmDetailData.edit_status === 1) return window.alert('이미 승인되었습니다');
    if (confirmDetailData.edit_status === 2) return window.alert('이미 반려되었습니다');
    if (comment === '') return window.alert('반려사유를 입력해주세요');

    if (window.confirm('유저등록 데이터를 반려하시겠습니까?')) {
      const data = delAdminConfirm(confirmDetailData.edit_idx, comment);
      console.log(data);
      setOpenModal(false);
      setComment('')
    }
    // const copiedList = [...confirmList];
    // const del
  }

  return (
    <Grid className={classes.background}>
      <Grid className={classes.container}>
        
        <Grid 
          className={classes.header} 
          container 
          justifyContent='space-between' 
          alignItems='center'
        >
          <p>유저 등록 데이터 확인</p>
          <Grid onClick={closeModal}>
            <AiOutlineClose size='20'/>
          </Grid>
        </Grid>

        <Grid 
          className={classes.header}
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <p>등록 정보</p>
          <p>등록일자: {confirmDetailData.updated_at.split('T')[0]}</p>
        </Grid>

        <Grid>
          <ConfirmDetailInfoTable/>
        </Grid>

        <Grid className={classes.header}>
          <p>영양성분</p>
        </Grid>

        <Grid>
          <ConfirmDetailIngredientTable/>
        </Grid>

        <Grid container className={classes.reject}>
          <p>반려사유 : </p>
          <input 
            value={comment}
            onChange={commentHandler}
          />
        </Grid>

        <Grid 
          className={classes.buttonContainer}
          container 
          justifyContent='space-between' 
          alignItems='center'
        >
          <Grid>
            <span onClick={confirmData} >승인</span>
          </Grid>

          <Grid>
            <span onClick={rejectData} >반려</span>
          </Grid>

          <Grid onClick={closeModal} style={{background: '#b37171'}}>
            <span>닫기</span>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    background: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: '#00000067',
      zIndex: 100,
    },
    container: {
      position: 'relative',
      width: '80%',
      background: '#fff',
      boxShadow: '0 20px 30px rgb(0 0 0 / 30%)',
      margin: '50px auto 0 auto',
      zIndex: 101,
      padding: '40px 20px',
    },
    header: {
      width: '90%',
      margin: 'auto',
      padding: '0 10px',
      '& p': {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#666',
      },
      '& div': {
        cursor: 'pointer',
      }
    },
    buttonContainer: {
      width: '50%',
      margin: '20px auto',
      '& div': {
        width: 100,
        height: 30,
        background: '#67a56f',
        borderRadius: 5,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
          fontSize: 14,
          color: '#fff',
        }
      }
    },
    reject: {
      width: '70%',
      margin: 'auto',
      '& p': {
        width: '10%',
      },
      '& input': {
        width: '80%',
        height: 50,
        
      }
    }
  })
)