import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useSetRecoilState } from "recoil";
import { getAdminUserDetail } from "../../../apis/user";
import { Member, member_join_type } from "../../../model/User";
import { UserDetailState, UserModalState } from "../../../states/UserState";

interface Props {
  member: Member;
}

export default function UserTableEl({member}: Props) {
  const classes = useStyle()
  const setOpenModal = useSetRecoilState(UserModalState);
  const setUserDetail = useSetRecoilState(UserDetailState);

  const {
    member_idx, 
    member_id, 
    member_join_type, 
    member_name, 
    member_phone, 
    member_email, 
    join_route} = member;

  const openDetail = async () => {
    const data = await getAdminUserDetail(member_idx);
    console.log(data);
    setUserDetail(data);
    setOpenModal(true);
  }

  function joinType(member_join_type: member_join_type): string {
    let result = ''
    switch (member_join_type) {
      case 'N' || 'AN':
        result = '네이버';
        break;
      case 'K' || 'AK':
        result = '카카오';
        break;
      case 'C':
        result = '키토테이블';
        break
    }
    return result;
  }

  return (
    <>
      <Grid className={classes.table} onClick={openDetail}>
        <p>{member_id}</p>
        <div/>
        <p>{member_name}</p>
        <div/>
        <p>{member_phone}</p>
        <div/>
        <p>{member_email}</p>
        <div/>
        <p>{joinType(member_join_type)}</p>
      </Grid>
      <div className={classes.line}/>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    table: {
      width: '90%',
      height: 25,
      margin: 'auto',
      background: '#fff',
      display: 'grid',
      gridTemplateColumns: '17% 1% 17% 1% 24% 1% 24% 1% 14%',
      alignItems: 'center',
      cursor: 'pointer',
      '& p': {
        fontSize: 14,
        fontWeight: 500,
        color: '#414141',
        textAlign: 'center',
        margin: 0,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& div': {
        width: 1,
        height: 30,
        border: 'none',
        background: '#999999',
      },
    },
    line: {
      width: '85%',
      margin: '5px auto',
      height: 0.5,
      background: '#afafaf',
    }
  })
)