import { makeStyles, Grid, createStyles } from "@material-ui/core";

interface Props {
  type: string;
  placeholder: string;
  setState(state: string): void;
}

export default function InputBox({type, placeholder, setState}: Props) {
  const classes = useStyle();
  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState(e.target.value);
  }

  return (
    <Grid className={classes.container}>
      <input type={type} placeholder={placeholder} onChange={change} />
    </Grid>
  )
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      width: '100%',
      border: '1px solid #08183a',
      borderRadius: 14,
      padding: '10px 20px',
      boxSizing: 'border-box',
      '& input': {
        border: 'none',
        outline: 'none',
        width: '100%',
        fontSize: 16,
      }
    }
  })
)