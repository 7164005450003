import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { MouseEvent } from "react";
import { useSetRecoilState } from "recoil";
import { getAdminFoodDetailData } from "../../../apis/food";
import { getAdminUserDetail } from "../../../apis/user";
import { FoodNormalData } from "../../../model/Food";
import { Member, member_join_type } from "../../../model/User";
import { foodDetailState, foodModalState } from "../../../states/FoodState";

interface Props {
  food: FoodNormalData;
}

export default function FoodTableEl({food}: Props) {
  const classes = useStyle()
  const setOpenModal = useSetRecoilState(foodModalState);
  const setFoodDetail = useSetRecoilState(foodDetailState);

  const {
    creator,
    food_brand,
    food_id,
    food_idx,
    food_name,
    grade_avg,
    reg_count,
    review_count,
    food_img,
  } = food;

  const openDetail = async () => {
    const data = await getAdminFoodDetailData(food_idx);
    console.log(data);
    setFoodDetail(data);
    setOpenModal(true);
  }

  const linkToImg = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    food_img !== null &&
    window.open(food_img, '_blank')
  }

  return (
    <>
      <Grid className={classes.table} onClick={openDetail}>
        <p>{food_idx}</p>
        <div/>
        <p>{food_brand === '0' ? '-' : food_brand}</p>
        <div/>
        <p>{food_id}</p>
        <div/>
        <p>{food_name}</p>
        <div/>
        <p>{grade_avg}</p>
        <div/>
        <p>{review_count}</p>
        <div/>
        <p>{reg_count}</p>
        <div/>
        <p>{creator}</p>
        <div/>
        <p style={food_img ? {color: '#3e57c7'}:{}} onClick={linkToImg}>{food_img ? '링크' : '-'}</p>
      </Grid>
      <div className={classes.line}/>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    table: {
      width: '90%',
      height: 25,
      margin: 'auto',
      background: '#fff',
      display: 'grid',
      gridTemplateColumns: '14% 0.3% 15% 0.3% 14% 0.3% 18% 0.3% 6% 0.3% 6% 0.3% 6% 0.3% 10% 0.3% 8%',
      alignItems: 'center',
      cursor: 'pointer',
      '& p': {
        fontSize: 14,
        fontWeight: 500,
        color: '#414141',
        textAlign: 'center',
        margin: 0,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& div': {
        width: 1,
        height: 30,
        border: 'none',
        background: '#999999',
      },
    },
    existLink: {
      color: '#5755d8'
    },
    line: {
      width: '90%',
      margin: '5px auto',
      height: 0.5,
      background: '#afafaf',
    }
  })
)