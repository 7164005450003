import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { PropsWithChildren } from "react";

interface Props {
  onClick?(): void;
}

export default function SubmitButton({onClick, children}: PropsWithChildren<Props>) {
  const classes = useStyles();

  return (
    <div className={classes.container} onClick={onClick}>
      <span>
        {children}
      </span>
    </div>
  )
}

const useStyles = makeStyles(() => 
  createStyles({
    container: {
      width: 'auto',
      height: 40,
      border: '1px solid #3788ca',
      borderRadius: 5,
      padding: 10,
      boxSizing: 'border-box',
      cursor: 'pointer',
      '&:hover': {
        background: '#e7e7e7',
      },
      '& span': {
        fontSize: 14,
        fontWeight: 500,
        textAlign: 'center',
      }
    }
  })
)