import { makeStyles, Grid, createStyles } from "@material-ui/core";
import React, { useState, KeyboardEvent } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getAdminSearchFoodList } from "../../../apis/food";
import { foodFilterState, foodModalState, foodModalTypeState, foodNormalListState } from "../../../states/FoodState";

export default function FoodSearchContainer() {
  const classes = useStyle();
  const [filter, setFilter] = useRecoilState(foodFilterState);
  const setFoodData = useSetRecoilState(foodNormalListState);
  const [word, setWord] = useState('');
  const setOpenModal = useSetRecoilState(foodModalState);
  const setTypeModal = useSetRecoilState(foodModalTypeState);
  const [countPage, setCountPage] = useState(1);

  const changeFilterValue = (e: any) => {
    setFilter(e.target.value);
  }

  const changeWordValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWord(e.target.value);
  }

  const searchWord = async () => {
    const data = await getAdminSearchFoodList(word);
    setFoodData(data.results);
    setCountPage(data.countForPage);
  }

  const searchByEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchWord()
    }
  }

  const createFood = () => {
    setOpenModal(true);
    setTypeModal(1);
  }

  return (
    <Grid 
      className={classes.container} 
      container
      justifyContent='center'
      alignItems='center'
    >
      
      <Grid>
        <select 
          className={classes.selectBox} 
          onChange={changeFilterValue} 
          value={filter}
        >
          <option value={0}>기본 정렬</option>
          <option value={1}>리뷰 많은 순</option>
          <option value={2}>평점 높은 순</option>
          <option value={3}>음식기록 많은 순</option>
          <option value={4}>유저가 등록한 음식</option>
        </select>
      </Grid>

      <Grid 
        className={classes.searchBox}
        container
        justifyContent='center'
        alignItems='center'
      >
        <input 
        placeholder='찾고싶은 키워드를 입력해주세요' 
        onChange={changeWordValue}
        onKeyPress={searchByEnter}
        />
      </Grid>

      <Grid 
        className={classes.searchBtn} 
        onClick={searchWord}
        container 
        justifyContent='center'
        alignItems='center'
      >
        <span>검색</span>
      </Grid>

      <Grid 
        className={classes.createBtn} 
        onClick={createFood}
        container 
        justifyContent='center'
        alignItems='center'
      >
        <span>등록</span>
      </Grid>

    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      position: 'relative',
      width: '90%',
      height: 80,
      margin: 'auto',
      padding: '20px 20px',
      background: '#fff',
      boxShadow: '0 20px 30px rgb(0 0 0 / 30%)',
    },
    selectBox: {
      height: 26,
      outline: 'none',
    },
    searchBox: {
      width: '40%',
      height: 30,
      border: '1px solid #74759c',
      borderRadius: 5,
      padding: '4px 15px',
      margin: '0 10px',
      '& input': {
        width: '100%',
        border: 'none',
        outline: 'none',
        fontSize: 14,
      }
    },
    searchBtn: {
      width: 100,
      height: 30,
      background: '#74759c',
      borderRadius: 5,
      cursor: 'pointer',
      '& span': {
        fontSize: 14,
        color: '#fff',
      }
    },
    createBtn: {
      width: 100,
      height: 30,
      background: '#67a56f',
      borderRadius: 5,
      cursor: 'pointer',
      marginLeft: 30,
      '& span': {
        fontSize: 14,
        color: '#fff',
      }
    }
  })
)