import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import StoreForm from "../elements/StoreForm";
import { setAdminProduct } from "../../../apis/store";
import axios from "axios";
import { getCookie } from "../../../module/Cookie";
import { ArrProduct, StoreProductData } from "../../../model/Store";
import { useRecoilState } from "recoil";
import { StoreProductState } from "../../../states/StoreState";

interface Props {
  open: boolean;
  setOpen(open: boolean): void;
  _data: ArrProduct
}

export default function StoreModal({open, setOpen, _data}: Props) {
  const classes = useStyle();

  const productData = {
    product_name: '',
    product_url: '',
    product_ranking: null,
    product_img: undefined,
  }

  const [product, setProduct] = useRecoilState(StoreProductState);
  const [data, setData] = useState<any>(productData);
  const [file, setFile] = useState<any>();

  const formData = new FormData();
  const setProductFile = (e: any) => {
    let imgFile = e.target.files[0];
    setFile(imgFile)
  }

  const createProduct = () => {
    if (_data.length < 10) {
      formData.append('product_name', data.product_name);
      formData.append('product_url', data.product_url);
      formData.append('product_ranking', data.product_ranking);
      formData.append('product_img', file);
      const token = getCookie('is_login');

      axios({
        method: 'post',
        url: '/admin/store/product',
        data: formData,
        headers: { "Content-Type": `multipart/form-data`, adt: token}
      }).then((res) => {
        // api response 수정 요청!!
        // let newData: StoreProductData = {
        //   created_at: '이것도',
        //   product_idx: 16, // 이것도 받아야함
        //   product_img: '이것도 받아야함',
        //   product_name: '',
        //   product_ranking: 6,
        //   product_url: '' //  사실 다 받아야함
        // }
        // let newList = [newData, ...product];
        // setProduct(newList)
        console.log(res)
        setOpen(false);
      }).catch((err) => {
        console.log(err,'err')
      })
    } else {
      window.alert('더 추가할 수 없어요!')
    }
  }

  return (
    <Grid className={classes.background}>
      <Grid className={classes.container}>
        
        <Grid className={classes.header} container justifyContent='space-between' alignItems='center'>
          <p>등록 하기!</p>
          <Grid onClick={()=>setOpen(false)}>
            <AiOutlineClose size='20'/>
          </Grid>
        </Grid>

        <Grid container alignItems='center'>
          <StoreForm label='인기상품 등록' data={data} setData={setData} onChange={setProductFile}/>
        </Grid>

        <Grid>
          <Grid className={classes.btnContainer} onClick={createProduct} container justifyContent='center' alignItems='center'>
            <span>등록</span>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    background: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: '#00000067',
      zIndex: 100,
    },
    container: {
      position: 'relative',
      width: '60%',
      background: '#fff',
      boxShadow: '0 20px 30px rgb(0 0 0 / 30%)',
      margin: '50px auto 0 auto',
      zIndex: 101,
      padding: '10px 20px',
    },
    header: {
      padding: '0 10px',
      '& p': {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#666',
      },
      '& div': {
        cursor: 'pointer',
      }
    },
    btnContainer: {
      width: 100,
      height: 40,
      margin: '10px auto',
      background: '#376a7e',
      borderRadius: 10,
      cursor: 'pointer',
      '& span': {
        fontSize: 14,
        color: '#fff',
      }
    }
  })
)