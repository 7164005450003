import { CustomFoodData, CustomFoodEditData, FoodDetailData, FoodImgData, ResponseFoodList, ResponseMsg, SortingFilterNumber } from "../model/Food";
import { get, post, patch, del } from "../module/HttpWebClient"


export async function getAdminDefaultFoodList(page: number, filter: SortingFilterNumber): Promise<ResponseFoodList> {
  const url = `/admin/food/list/${page}/${filter}`;
  const { data } = await get<ResponseFoodList>(url);
  return data;
}

export async function getAdminSearchFoodList(word: string): Promise<ResponseFoodList> {
  const url = `/admin/food/search/${word}`;
  const { data } = await get<ResponseFoodList>(url);
  return data;
}

export async function getAdminFoodDetailData(food_idx: number): Promise<FoodDetailData> {
  const url = `/admin/food/detail/${food_idx}`;
  const { data } = await get<FoodDetailData>(url);
  return data;
}

export async function setAdminCustomFood(request: CustomFoodData): Promise<ResponseMsg> {
  const url = '/admin/food';
  const { data } = await post<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function editAdminFood(request: CustomFoodEditData): Promise<ResponseMsg> {
  const url = '/admin/food';
  const { data } = await patch<ResponseMsg>(url, {
    ...request
  });
  return data;
}

export async function delAdminFood(food_idx: number): Promise<ResponseMsg> {
  const url = `/admin/food/${food_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function setAdminFoodImg(request: FoodImgData): Promise<ResponseMsg> {
  const url = '/admin/food/img';
  const { data } = await post<ResponseMsg>(url, {
    ...request
  });
  return data;
}

