import axios, { AxiosRequestConfig } from "axios"

// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (401 === error.response.status) {
//       if (window.location.pathname === "/login") return
//       if (window.location.pathname.includes("/join")) return
//       window.location.href = "/login"
//     } else {
//       return Promise.reject(error)
//     }
//   }
// )

export async function get<T>(url: string, config?: AxiosRequestConfig) {
  return axios.get<T>(url, config)
}

export async function post<T>(url: string, data?: any, config?: AxiosRequestConfig) {
  return axios.post<T>(url, data, config)
}

export async function put<T>(url: string, data?: any, config?: AxiosRequestConfig) {
  return axios.put<T>(url, data, config)
}

export async function patch<T>(url: string, data?: any, config?: AxiosRequestConfig) {
  return axios.patch<T>(url, data, config)
}

export async function del<T>(url: string, config?: AxiosRequestConfig) {
  return axios.delete<T>(url, config)
}

interface SuccessWebResponse<T> {
  data: T
  error: false
}
interface ErrorWebResponse {
  error: true
  message: string
  code: string
}
export type WebResponse<T> = SuccessWebResponse<T> | ErrorWebResponse
