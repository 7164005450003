import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { TiDelete } from "react-icons/ti";
import { delAdminInoutTalkItem } from "../../../apis/inoutTalk";
import { ItemData } from "../../../model/InoutTalk";

interface Props {
  itemData: ItemData;
  itemUseState: any
}

export default function TalkItem({itemData, itemUseState}: Props) {
  const classes = useStyle();
  const {item_idx, food_idx, item_name} = itemData;
  const {item, setItem} = itemUseState;

  // console.log(typeof(item))
  // console.log(item)
  const delItem = async () => {
    if (window.confirm('삭제하시겠습니까?')){
      const msg = await delAdminInoutTalkItem(item_idx);
      console.log(msg);
      let deletedList = item.filter((food: any) => food.item_idx !== item_idx);
      setItem(deletedList);
    }
  }

  return (
    <Grid className={classes.card} container alignItems='center'>
      <Grid className={classes.cardHeader} item container alignItems='center' justifyContent='center'>
        <span>아이템</span>
      </Grid>
      <Grid className={classes.contents}>
        <p>{item_idx}</p>
        <p>{food_idx}</p>
        <p>{item_name}</p>
      </Grid>
      <Grid className={classes.delBtn} onClick={delItem} container item justifyContent='center' alignItems='center'>
        <TiDelete size='22' color='#777'/>
      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    card: {
      height: 40,
      width: '80%',
      margin: 'auto',
      background: '#fff',
      boxShadow: '0 2px 10px rgb(0 0 0 / 20%)',
      marginBottom: 10,
      cursor: 'pointer',
    },
    cardHeader: {
      width: 40,
      height: '100%',
      background: '#ebdcdc',
      '& span': {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#fff',
      },
    },
    contents: {
      padding: '0 25px',
      width: 'calc(100% - 80px)',
      boxSizing: 'border-box',
      display: 'grid',
      gridTemplateColumns: '30px 30px 1fr',
      '& p': {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#888',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        padding: '0 5px',
      }
    },
    delBtn: {
      height: 40,
      width: 40,
      cursor: 'pointer',
    }
  })
)