import { makeStyles, Grid, createStyles } from "@material-ui/core";

interface Props {
  onClick?:() => void;
  text: string;
}

export default function LoginBtn({text, onClick}: Props) {
  const classes = useStyle();

  return (
    <div className={classes.buttonBox} onClick={onClick}>
      <p>
        {text}
      </p>
    </div>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    buttonBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 100,
      margin: 'auto',
      padding: '4px 12px',
      cursor: 'pointer',
      background: '#1b446b',
      borderRadius: 10,
      '& p': {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#fff',
      }
    }
  })
)