import { makeStyles, Grid, createStyles } from "@material-ui/core";
import HeaderEl from "../../Components/HeaderEl";
import Header from "../../shared/Header";
import SideNav from "../../shared/SideNav";
import ConfirmList from "./components/ConfirmList";

export default function Confirm() {
  const classes = useStyle();

  return (
    <>
    <Header/>
    <SideNav/>
    <HeaderEl title='회원등록음식 관리'/>
    <Grid className={classes.container}>
      <ConfirmList/>
    </Grid>
  </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      padding: '20px 50px',
    },
    header: {
      width: '100%',
      '& p': {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#666',
      },
    },
  })
)