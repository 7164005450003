import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { FaUserFriends, FaUserPlus } from "react-icons/fa";

interface Props {
  title: string;
  count: any;
}

export default function UserCountingCard({title, count}: Props) {
  const classes = useStyle();

  const userType = () => {
    switch (title) {
      case '모든 회원':
        return {
          icon: <FaUserFriends size='20' color='#fff'/>,
          background: '#666c9e',
        }
      case '오늘 가입한 회원':
        return {
          icon: <FaUserPlus size='16' color='#fff'/>,
          background: '#4f9477',
        }
      case '이번주에 가입한 회원':
        return {
          icon: <FaUserPlus size='16' color='#fff'/>,
          background: '#4f9477',
        }
      case '이번달에 가입한 회원':
        return {
          icon: <FaUserPlus size='16' color='#fff'/>,
          background: '#4f9477',
        }
      case 'from Inout':
        return {
          icon: <FaUserPlus size='16' color='#fff'/>,
          background: '#985caf',
        }
      case 'from Ketotable':
        return {
          icon: <FaUserPlus size='16' color='#fff'/>,
          background: '#8a553d',
        }
    }
  }

  return (
    <Grid
      className={classes.container}
      style={{background: userType()?.background}}
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
      >
        {userType()?.icon}
        <span>
          {title}
        </span>
      </Grid>
        
      <p>{count} 명</p>
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '20%',
      height: 60,
      margin: 10,
      padding: 30,
      borderRadius: 30,
      background: '#5c5c5c',
      boxShadow: '0 10px 30px rgb(0 0 0 / 10%)',

      '& div': {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '& span': {
          marginLeft: 10,
          fontSize: 14,
          color: '#fff',
        },
      },
      '& p': {
        fontSize: 20,
        fontWeight: '500',
        color: '#fff',
        margin: 0,
        marginTop: 20,
      }
    }
  })
)