import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { confirmDetailState } from "../../../states/ConfirmState";

export default function ConfirmDetailIngredientTable() {
  const classes = useStyle()
  const confirmDetailData = useRecoilValue(confirmDetailState);

  return (
    <>
      <div className={classes.line}/>
      <Grid className={classes.table}>
        <p>칼로리</p>
        <div/>
        <span>{confirmDetailData.calories}</span>
        <div/>
        <p>탄수화물</p>
        <div/>
        <span>{confirmDetailData.carbohydrate}</span>
        <div/>
        <p>순 탄수</p>
        <div/>
        <span>{confirmDetailData.net_carbohydrate}</span>
        <div/>
        <p>식이섬유</p>
        <div/>
        <span>{confirmDetailData.fiber}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>당</p>
        <div/>
        <span>{confirmDetailData.sugar}</span>
        <div/>
        <p>대체당</p>
        <div/>
        <span>{confirmDetailData.sugar_sub}</span>
        <div/>
        <p>단백질</p>
        <div/>
        <span>{confirmDetailData.protein}</span>
        <div/>
        <p>나트륨</p>
        <div/>
        <span>{confirmDetailData.sodium}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>지방</p>
        <div/>
        <span>{confirmDetailData.fat}</span>
        <div/>
        <p>포화 지방</p>
        <div/>
        <span>{confirmDetailData.saturated_fat}</span>
        <div/>
        <p>불포화 지방</p>
        <div/>
        <span>{confirmDetailData.monounsaturated_fat}</span>
        <div/>
        <p>다포화 지방</p>
        <div/>
        <span>{confirmDetailData.polyunsaturated_fat}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>트랜스 지방</p>
        <div/>
        <span>{confirmDetailData.trans_fat}</span>
        <div/>
        <p>콜레스테롤</p>
        <div/>
        <span>{confirmDetailData.cholesterol}</span>
        <div/>
        <p>칼륨</p>
        <div/>
        <span>{confirmDetailData.potassium}</span>
        <div/>
        <p></p>
        <div/>
        <span></span>
      </Grid>
      <div className={classes.line}/>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    table: {
      width: '90%',
      height: 35,
      margin: 'auto',
      background: '#fff',
      display: 'grid',
      gridTemplateColumns: '12.4% 0.1% 12.4% 0.1% 12.4% 0.1% 12.4% 0.1% 12.4% 0.1% 12.4% 0.1% 12.4% 0.1% 12.4%',
      justifyItems: 'center',
      alignItems: 'center',
      '& p': {
        width: '100%',
        fontSize: 13,
        fontWeight: 500,
        color: '#414141',
        textAlign: 'center',
        padding: '9.5px 0',
        margin: 0,
        background: '#dddddd'
      },
      '& div': {
        width: '100%',
        height: 35,
        border: 'none',
        background: '#999999',
      },
      '& span': {
        fontSize: 12,
        fontWeight: 400,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }
    },
    line: {
      width: '90%',
      margin: 'auto',
      height: 1,
      background: '#afafaf',
    }
  })
)