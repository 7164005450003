import { ResponseMsg, StoreProductRequest, ArrProduct, StorePromotionRequest, ArrPromotion, PromotionSort } from '../model/Store';
import {del, get, patch, post} from '../module/HttpWebClient';

export async function setAdminProduct(request: StoreProductRequest): Promise<ResponseMsg> {
  const url = '/admin/store/product';
  const { data } = await post<ResponseMsg>(url, {
    ...request
  });
  return data;
}

export async function getAdminProduct(): Promise<ArrProduct> {
  const url = '/admin/store/product/list';
  const { data } = await get<ArrProduct>(url);
  return data;
}

export async function delAdminProduct(product_idx: number): Promise<ResponseMsg> {
  const url = `/admin/store/product/${product_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function setAdminPromotion(request: StorePromotionRequest): Promise<ResponseMsg> {
  const url = '/admin/store/promotion';
  const { data } = await post<ResponseMsg>(url, {
    ...request
  });
  return data;
}

export async function getAdminPromotion(): Promise<ArrPromotion> {
  const url = '/admin/store/promotion/list';
  const { data } = await get<ArrPromotion>(url);
  return data;
}

export async function delAdminPromotion(promotion_idx: number): Promise<ResponseMsg> {
  const url = `/admin/store/promotion/${promotion_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function editPromotionSort(request: PromotionSort): Promise<ArrPromotion> {
  const url = '/admin/store/promotion/sort';
  const { data } = await patch<ArrPromotion>(url, {
    ...request
  });
  return data;
}

export async function editPromotion(promotion_idx: number ,request: StorePromotionRequest): Promise<ResponseMsg> {
  const url = `/admin/store/promotion/${promotion_idx}`;
  const { data } = await patch<ResponseMsg>(url, {
    ...request
  });
  return data;
}