import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { GoSignOut } from "react-icons/go";

export default function Header() {
  const classes = useStyle();

  return (
    <>
      <Grid 
        className={classes.container}
        container
        justifyContent='space-between'
        alignItems='center'
      >
        <span>
          Ketotable Inout 관리자 페이지
        </span>

        <Grid className={classes.signout}>
          <GoSignOut size='26' color='#fff'/>
        </Grid>
      </Grid>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      height: 70,
      padding: '10px 30px',
      width: '100vw',
      backgroundColor: '#3f3f3f',
      zIndex: 999,
      '& span': {
        fontSize: 20,
        color: '#fff',
      }
    },
    signout: {
      cursor: 'pointer'
    }
  })
)