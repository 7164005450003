import React from 'react';
import ReactDOM from 'react-dom';
import App from './shared/App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { getCookie } from "./module/Cookie";
import { BACKURL } from "./shared/link";
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';

axios.interceptors.request.use((config: any) => {
  const token = getCookie('is_login');
  console.log('인터셉터 토큰 체크', token);
  config.headers.adt = token;
  return config;
});

axios.defaults.baseURL = BACKURL;


ReactDOM.render(
  <RecoilRoot>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </RecoilRoot>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
