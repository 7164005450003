import { InoutTalkItems, InoutTalkMains, InoutTalkRequestData, InoutTalkSubs, ResponseMsg } from '../model/InoutTalk';
import {del, get, patch, post} from '../module/HttpWebClient';


export async function setAdminInoutTalk(request: InoutTalkRequestData): Promise<ResponseMsg> {
  const url = '/admin/inoutTalk';
  const { data } = await post<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function getAdminInoutTalkMain(): Promise<InoutTalkMains> {
  const url = '/admin/inoutTalk/list';
  const { data } = await get<InoutTalkMains>(url);
  return data;
}

export async function getAdminInoutTalkSub(main_title_idx: number): Promise<InoutTalkSubs> {
  const url = `/admin/inoutTalk/detail/${main_title_idx}`;
  const { data } = await get<InoutTalkSubs>(url);
  return data;
}

export async function getAdminInoutTalkItem(sub_title_idx: number): Promise<InoutTalkItems> {
  const url = `/admin/inoutTalk/items/${sub_title_idx}`;
  const { data } = await get<InoutTalkItems>(url);
  return data;
}

export async function delAdminInoutTalkMain(main_title_idx: number): Promise<ResponseMsg> {
  const url = `/admin/inoutTalk/main/${main_title_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function delAdminInoutTalkSub(sub_title_idx: number): Promise<ResponseMsg> {
  const url = `/admin/inoutTalk/sub/${sub_title_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function delAdminInoutTalkItem(item_idx: number): Promise<ResponseMsg> {
  const url = `/admin/inoutTalk/items/${item_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}