import { atom } from "recoil";
import { Member, MemberDetail } from "../model/User";

export const UserState = atom<Member[]>({
  key: 'UserState',
  default: [],
})

export const UserModalState = atom<boolean>({
  key: 'UserModalState',
  default: false,
})

export const UserDetailState = atom<MemberDetail>({
  key: 'UserDetailState',
  default: {} as MemberDetail
})