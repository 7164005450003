import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { getAdminDashboardUser } from "../../../apis/dashboard";
import { dashboardUserState } from "../../../states/DashboardState";
import UserCountingCard from "../elements/UserCountingCard";

export default function DashboardUser() {
  const classes = useStyle();
  const [dashboardUserData, setDashboardUserData] = useRecoilState(dashboardUserState);

  useEffect(() => {
    (async () => {
      // if (dashboardUserData === undefined) {
        const data = await getAdminDashboardUser();
        console.log(data);
        setDashboardUserData(data);
      // }
    })();
  }, [])

  return (
    <Grid
      className={classes.container}
    >
      <Grid
        className={classes.userContent}
        container
        justifyContent='center'
      >
        <UserCountingCard title='모든 회원' count={dashboardUserData?.allUserCount}/>
        <UserCountingCard title='from Inout' count={dashboardUserData?.fromInoutJoinCount}/>
        <UserCountingCard title='from Ketotable' count={dashboardUserData?.fromKetoJoinCount}/>
        <UserCountingCard title='오늘 가입한 회원' count={dashboardUserData?.dayJoinCount}/>
        <UserCountingCard title='이번주에 가입한 회원' count={dashboardUserData?.weekJoinCount}/>
        <UserCountingCard title='이번달에 가입한 회원' count={dashboardUserData?.monthJoinCount}/>
      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      width: '100%',
      margin: '20px auto',
      padding: 20,
      background: '#fff',
      boxShadow: '0 10px 30px rgb(0 0 0 / 20%)',
    },
    userContent: {
      width: '80%',
      margin: 'auto',
    }
  })
)