import { makeStyles, Grid, createStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { getCookie } from '../module/Cookie';
import DashBorad from '../pages/DashBoard/DashBoard';
import Foods from '../pages/Foods/Foods';
import InoutTalk from '../pages/InoutTalk/InoutTalk';
import Login from '../pages/Login/Login';
import Store from '../pages/Store/Store';
import Users from '../pages/Users/Users';
import Header from './Header';
import { ACCESS_TOKEN } from './link';
import SideNav from './SideNav';
import { useHistory } from 'react-router';
import Confirm from '../pages/Confirm/Confirm';
import Review from '../pages/Review/Review';
import RegisterInoutTalk from '../pages/InoutTalk/RegisterInoutTalk';

const App = () => {
  const classes = useStyle();
  const history = useHistory();  
  const token = getCookie(ACCESS_TOKEN);
  useEffect(() => {
    if (!token) {
      history.push('/login');
    }
  }, [])

  return (
    <>
        <Grid  className={classes.container}>
          <Route path='/' exact component={DashBorad} />
          <Route path='/users' exact component={Users} />
          <Route path='/foods' exact component={Foods} />
          <Route path='/inoutTalk' exact component={InoutTalk} />
          <Route path='/inoutTalk/submit' exact component={RegisterInoutTalk} />
          <Route path='/store' exact component={Store} />
          <Route path='/confirm' exact component={Confirm} />
          <Route path='/review' exact component={Review} />
        </Grid>
        
        <Route path='/login' exact component={Login} />

    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      position: 'relative',
      padding: '70px 0 0 300px',
      width: '100vw',
      // height: '100vh',
      boxSizing: 'border-box',
      backgroundColor: '#ffffff',
      zIndex: 0,
    }

  })
)

export default App;



