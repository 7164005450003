import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { AiOutlineClose } from "react-icons/ai";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { UserDetailState, UserModalState } from "../../../states/UserState";
import UserDetailTableEl from "../elements/UserDetailTableEl";
import UserGoalTable from "../elements/UserGoalTable";

export default function UserDetailModal() {
  const classes = useStyle();
  const setOpenModal = useSetRecoilState(UserModalState);
  const userDetail = useRecoilValue(UserDetailState);

  const updatedTimeToString = userDetail.created_at !== null && userDetail.created_at.split('T')[0];

  return (
    <Grid className={classes.background}>
      <Grid className={classes.container}>
        
        <Grid 
          className={classes.header} 
          container 
          justifyContent='space-between' 
          alignItems='center'
        >
          <p>회원 상세정보</p>
          <Grid onClick={()=>setOpenModal(false)}>
            <AiOutlineClose size='20'/>
          </Grid>
        </Grid>

        <Grid 
          className={classes.header} 
          container 
          justifyContent='flex-end'
        >
          <p>
            가입일자 : {updatedTimeToString}
          </p>
        </Grid>

        <Grid>
          <UserDetailTableEl/>
        </Grid>

        <Grid className={classes.header}>
          <p>회원 목표</p>
        </Grid>

        <Grid>
          <UserGoalTable/>
        </Grid>

      </Grid>
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    background: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: '#00000067',
      zIndex: 100,
    },
    container: {
      position: 'relative',
      width: '60%',
      background: '#fff',
      boxShadow: '0 20px 30px rgb(0 0 0 / 30%)',
      margin: '50px auto 0 auto',
      zIndex: 101,
      padding: '40px 20px',
    },
    title: {

    },
    header: {
      width: '90%',
      margin: 'auto',
      padding: '0 10px',
      '& p': {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#666',
      },
      '& div': {
        cursor: 'pointer',
      }
    },
  })
)