import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { getAdminDefaultFoodList } from "../../../apis/food";
import { foodFilterState, foodModalState, foodNormalListState } from "../../../states/FoodState";
import FoodTableEl from "../elements/FoodTableEl";
import PageContainer from "../../../Components/PageContainer";
import FoodModal from "./FoodModal";

export default function FoodSearchResult() {
  const classes = useStyle();
  const [page, setPage] = useState(0);
  const filter = useRecoilValue(foodFilterState);
  const [foodList, setFoodList] = useRecoilState(foodNormalListState);
  const [countPage, setCountPage] = useState(1);
  const openModal = useRecoilValue(foodModalState);
  console.log(openModal)

  useEffect(() => {
    (async () => {
      const data = await getAdminDefaultFoodList(page, filter);
      console.log(data);
      setFoodList(data.results);
      setCountPage(data.countForPage);
    })();

    return () => {
      setFoodList([]);
      setCountPage(1);
    }
  }, [page, filter]);

  return (
    <Grid className={classes.container}>
      <Grid className={classes.table}>
        <p>No</p>
        <div/>
        <p>브랜드</p>
        <div/>
        <p>음식 ID</p>
        <div/>
        <p>이름</p>
        <div/>
        <p>평점</p>
        <div/>
        <p>리뷰 수</p>
        <div/>
        <p>등록 수</p>
        <div/>
        <p>출처</p>
        <div/>
        <p>이미지링크</p>
      </Grid>
      <div className={classes.line}/>
      {foodList.map((food, index) => {
        return <FoodTableEl food={{...food}} key={food.food_idx}/>;
      })}

      <PageContainer state={{page, countPage}} setState={setPage}/>
      {openModal && <FoodModal/>}
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      position: 'relative',
      width: '90%',
      margin: '20px auto',
      padding: '20px 20px',
      background: '#fff',
      boxShadow: '0 10px 30px rgb(0 0 0 / 20%)',
    },
    table: {
      width: '90%',
      height: 25,
      margin: 'auto',
      background: '#dddddd',
      display: 'grid',
      gridTemplateColumns: '14% 0.3% 15% 0.3% 14% 0.3% 18% 0.3% 6% 0.3% 6% 0.3% 6% 0.3% 10% 0.3% 8%',
      alignItems: 'center',
      cursor: 'pointer',
      '& p': {
        fontSize: 14,
        fontWeight: 500,
        color: '#414141',
        textAlign: 'center',
        margin: 0,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& div': {
        width: 1,
        height: 30,
        border: 'none',
        background: '#999999',
      },
    },
    line: {
      width: '90%',
      margin: 'auto',
      height: 0.5,
      background: '#afafaf',
    }
  })
)