import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { StorePromotionRequest } from "../../../model/Store";

interface Props {
  label: string;
  data: StorePromotionRequest;
  setData(state: StorePromotionRequest): void;
  onChange: any;
}

export default function StorePromotionForm({label, data, setData, onChange}: Props) {
  const classes = useStyle();

  const setPromotionName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({...data, promotion_name: e.target.value})
  }
  const setPromotionContent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({...data, promotion_content: e.target.value})
  }
  const setPromotionUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({...data, promotion_url: e.target.value})
  }
  const setPromotionStart = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({...data, start_at: e.target.value})
  }
  const setPromotionEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({...data, end_at: e.target.value})
  }

  return (
    <Grid className={classes.container}>
      <Grid className={classes.label}>
        <p>{label}</p>
      </Grid>
      <Grid className={classes.input}>
        <input placeholder='이름을 입력해주세요' onChange={setPromotionName} />
      </Grid>
      <Grid className={classes.input}>
        <input placeholder='내용을 입력해주세요' onChange={setPromotionContent} />
      </Grid>
      <Grid className={classes.input}>
        <input placeholder='URL를 입력해주세요' onChange={setPromotionUrl} />
      </Grid>
      <Grid className={classes.input}>
        <input type='date' placeholder='시작일을 입력해주세요' onChange={setPromotionStart} />
      </Grid>
      <Grid className={classes.input}>
        <input type='date' placeholder='마감일을 입력해주세요' onChange={setPromotionEnd} />
      </Grid>
      <Grid className={classes.input}>
        <input type='file' placeholder='파일을 업로드해주세요' onChange={onChange} />
      </Grid>
    </Grid>
  )
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      width: '100%'
    },
    label: {
      width: '85%',
      margin: 'auto',
      '& p': {
        fontSize: 14,
        color: '#666',
        margin: '5px 0',
      }
    },
    input: {
      width: '90%',
      margin: '3px auto',
      border: '1px solid #556586',
      borderRadius: 12,
      padding: '8px 12px',
      boxSizing: 'border-box',
      '& input': {
        border: 'none',
        outline: 'none',
        width: '100%',
        fontSize: 12,
      }
    }
  })
)