import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { ArrPromotion } from "../../../model/Store";
import { AiOutlinePlus } from "react-icons/ai";
import StoreModal from "./StoreModal";
import { useEffect, useState } from "react";
import PromotionItem from "./PromotionItem";
import { useRecoilState, useRecoilValue } from "recoil";
import { PromotionModalState, StorePromotionState } from "../../../states/StoreState";
import StorePromotionModal from "./StorePromotionModal";
import { editPromotionSort, getAdminPromotion } from "../../../apis/store";
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown} from "react-icons/md";

const initialGrabData = {
  target: null,
  position: null,
  move_up: [],
  move_down: [],
  updateList: []
}

export default function Promotion() {
  const classes = useStyle();
  const [open, setOpen] = useRecoilState(PromotionModalState);
  const [promotionList, setPromotionList] = useRecoilState(StorePromotionState);
  const copyList = [...promotionList];
  const [list, setList] = useState(copyList);
  const [grab, setGrab] = useState<any>(initialGrabData);
  const [isDrag, setIsDrag] = useState(false);
  const [target, setTarget] = useState(null);

  useEffect(() => {
    (async () => {
      const data = await getAdminPromotion();
      console.log(data)
      setPromotionList(data);
    })();
  }, [])

  useEffect(() => {}, [grab]);

  const _onDragOver = (e: any) => {
    e.preventDefault();

  }

  const _onDragStart = (e: any) => {
    setIsDrag(true);
    setGrab({
      ...grab,
      target: e.target,
      position: Number(e.target.dataset.position),
      updateList: [...list]
    });
    e.target.classList.add("grabbing");
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target);
  }

  const _onDragEnd = async (e: any) => {
    setIsDrag(false);
    e.target.classList.remove("grabbing");
    e.dataTransfer.dropEffect = "move";
    // setList([...grab.updateList]);
    setGrab({
      target: null,
      move_up: [],
      move_down: [],
      updateList: [],
    });
    e.target.style.visibility = 'visible';

 

    // console.log(Number(grab.target.dataset.position), Number(target))
    let _grab = Number(grab.target.dataset.position);
    let _target = Number(target);
    let isAble = (_grab === _target) || isNaN(_grab)  || isNaN(_target);
    if (!isAble) {
      let request = {
        sort: [
          {
            promotion_idx: promotionList[_grab].promotion_idx,
            promotion_sort: promotionList[_grab].promotion_sort,
          },
          {
            promotion_idx: promotionList[_target].promotion_idx,
            promotion_sort: promotionList[_target].promotion_sort,
          }
        ]
      }
      const data = await editPromotionSort(request);
      setPromotionList(data);
    }
  }

  const _onDragEnter = (e: any) => {
    let grabPosition = Number(grab.target.dataset.position);
    let listPosition = grab.position;
    let targetPosition = Number(e.target.dataset.position);
    setTarget(e.target.dataset.position)

    let move_up = [...grab.move_up];
    let move_down = [...grab.move_down];

    let data = [...grab.updateList];
    data[grabPosition] = data.splice(targetPosition, 1, data[grabPosition])[0];

    if (listPosition > targetPosition) {
      move_down.includes(targetPosition) ? move_down.pop() : move_down.push(targetPosition);
    } else if (listPosition < targetPosition) {
      move_up.includes(targetPosition) ? move_up.pop() : move_up.push(targetPosition);
    } else {
      move_down = [];
      move_up = [];
    }

    setGrab({
      ...grab,
      move_up,
      move_down,
      updateList: data,
      position: targetPosition
    });
  }
  
  const _onDragLeave = (e: any) => {
    if (e.target === grab.target) {
      e.target.style.visibility = "hidden";
    }
  }

  return (
    <Grid className={classes.container}
      onDragOver={_onDragOver}
    >
      {promotionList.map((item, index) => {
        let classNames = classes.list;
        grab.move_up.includes(index) && (classNames = classes.list_move_up);
        grab.move_down.includes(index) && (classNames = classes.list_move_down);
        return (
          <div
            className={classNames}
            key={item.promotion_idx}
            data-position={index}
            onDragStart={_onDragStart}
            onDragEnd={_onDragEnd}
            onDragEnter={_onDragEnter}
            onDragLeave={_onDragLeave}
            draggable
            style={isDrag ? {transition: "transform 200ms ease 0s"} : {}}
          >
            <PromotionItem {...item} key={item.promotion_idx} />
            <div className={classes.hoverBtn}>
              <MdOutlineKeyboardArrowUp size='22'/>
              <div/>
              <MdOutlineKeyboardArrowDown size='22'/>
            </div>
          </div>
        )
      })}
      <Grid className={classes.createBtn} onClick={()=>setOpen(true)} container justifyContent='center' alignItems='center'>
        <AiOutlinePlus size='24' color='#fff'/>
      </Grid>
      {open && <StorePromotionModal/>}
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      position: 'relative',
      width: '90%',
      margin: 'auto',
      padding: '40px 0',
      background: '#fff',
      boxShadow: '0 20px 30px rgb(0 0 0 / 30%)',
    },
    createBtn: {
      position: 'absolute',
      zIndex: 3,
      top: 20,
      right: 30,
      width: 50,
      height: 50,
      borderRadius: '50%',
      background: '#376a7e',
      cursor: 'pointer',
    },
    list: {
      width: '100%',
      position: 'relative',
      background: '#fff',
      zIndex: 1,
    },
    list_grabbing: {
      position: 'relative',
      background: '#fff',

    	cursor: 'grabbing',
      zIndex: 1,
    },
    list_move_up: {
      position: 'relative',
      background: '#fff',
      transform: 'translate(0, -121px)',
      zIndex: 1,
    },
    list_move_down: {
      position: 'relative',
      background: '#fff',
      transform : 'translate(0, 121px)',
      zIndex: 1,
    },
    hoverBtn: {
      position: 'absolute', 
      top: 'calc(50% - 27px)', 
      left: '10%', 
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 0,
      '& div': {
        width: '10px', 
        height: '10px', 
        borderRadius: '50%', 
        background: '#838383'
      }
    }
  })
)