import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { getAdminInoutTalkMain } from "../../apis/inoutTalk";
import { inoutMainState } from "../../states/InoutTalkState";
import MainTalk from "./components/MainTalk";
import Header from "../../shared/Header";
import SideNav from "../../shared/SideNav";
import HeaderEl from "../../Components/HeaderEl";
import { useHistory } from "react-router";

export default function InoutTalk(Props: any) {
  const classes = useStyle();
  const history = useHistory();
  const [mainData, setMainData] = useRecoilState(inoutMainState);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const data = await getAdminInoutTalkMain();
      console.log(data)
      setMainData(data.inoutTalkMainTitleList);
    })();
  }, [])


  const list = mainData
  if(!list) return <></>; 

  return (
    <>
      <Header/>
      <SideNav/>
      <HeaderEl title='인아웃토크 관리'/>
      <Grid className={classes.container}>
        <Grid className={classes.header} container justifyContent="flex-end" alignItems='center'>
          <Grid onClick={()=>history.push('/inoutTalk/submit')} container justifyContent='center' alignItems='center'><span>등록 하기</span></Grid>
        </Grid>
        {list.map((main, index) => {
          return <MainTalk {...main} key={index}/>
        })}
      {/* {openModal && <InoutTalkModal open={openModal} setOpen={setOpenModal}/>} */}
      </Grid>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      padding: '20px 50px',
    },
    header: {
      width: '100%',
      '& p': {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#666',
      },
      '& div': {
        width: 80,
        height: 30,
        background: '#376a7e',
        borderRadius: 5,
        cursor: 'pointer',
        '& span': {
          fontSize: 14,
          color: '#fff',
        }
      }
    },
  })
)