import { makeStyles, Grid, createStyles } from "@material-ui/core";

interface Props {
  title: string;
}

export default function HeaderEl({title}: Props) {
  const classes = useStyle();

  return (
    <Grid className={classes.container}>
      <p>
        {title}
      </p>
    </Grid>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    container: {
      width: '80%',
      margin: 'auto',
      '& p': {
        fontSize: 30,
        fontWeight: 500,
        color: '#3d3d3d',
      }
    }
  })
)