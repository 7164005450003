import { makeStyles, Grid, createStyles } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { confirmDetailState } from "../../../states/ConfirmState";

export default function ConfirmDetailInfoTable() {
  const classes = useStyle()
  const confirmDetailData = useRecoilValue(confirmDetailState);

  const statusToWord = (status: number) => {
    switch (status) {
      case 0:
        return '대기';
      case 1:
        return '승인';
      case 2:
        return '반려';
    }
  }


  return (
    <>
      <div className={classes.line}/>
      <Grid className={classes.table}>
        <p>No</p>
        <div/>
        <span>{confirmDetailData.edit_idx}</span>
        <div/>
        <p>타입</p>
        <div/>
        <span>{confirmDetailData.edit_type}</span>
        <div/>
        <p>상태</p>
        <div/>
        <span>{statusToWord(confirmDetailData.edit_status)}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>회원 이름</p>
        <div/>
        <span>{confirmDetailData.member_name}</span>
        <div/>
        <p>회원 idx</p>
        <div/>
        <span>{confirmDetailData.member_idx}</span>
        <div/>
        <p>생성자</p>
        <div/>
        <span>{confirmDetailData.creator}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>음식 ID</p>
        <div/>
        <span>{confirmDetailData.food_id}</span>
        <div/>
        <p>음식 이름</p>
        <div/>
        <span>{confirmDetailData.food_name}</span>
        <div/>
        <p>음식 타입</p>
        <div/>
        <span>{confirmDetailData.food_type}</span>
      </Grid>
      <div className={classes.line}/>

      <Grid className={classes.table}>
        <p>브랜드</p>
        <div/>
        <span>{confirmDetailData.food_brand}</span>
        <div/>
        <p>1회 제공 단위</p>
        <div/>
        <span>{confirmDetailData.metric_serving_unit} {confirmDetailData.measurement_description}</span>
        <div/>
        <p>1회 제공량</p>
        <div/>
        <span>{confirmDetailData.metric_serving_amount} {confirmDetailData.metric_serving_unit}</span>
      </Grid>
      <div className={classes.line}/>
    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    table: {
      width: '90%',
      height: 35,
      margin: 'auto',
      background: '#fff',
      display: 'grid',
      gridTemplateColumns: '16% 0.1% 16% 0.1% 16% 0.1% 16% 0.1% 16% 0.1% 16%',
      justifyItems: 'center',
      alignItems: 'center',
      '& p': {
        width: '100%',
        fontSize: 13,
        fontWeight: 500,
        color: '#414141',
        textAlign: 'center',
        padding: '9.5px 0',
        margin: 0,
        background: '#dddddd'
      },
      '& div': {
        width: '100%',
        height: 35,
        border: 'none',
        background: '#999999',
      },
      '& span': {
        fontSize: 12,
        fontWeight: 400,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }
    },
    line: {
      width: '90%',
      margin: 'auto',
      height: 1,
      background: '#afafaf',
    }
  })
)