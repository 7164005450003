import { makeStyles, Grid, createStyles } from "@material-ui/core";
import axios from "axios";
import { useState } from "react";
import { getCookie } from "../../../module/Cookie";
import InputBox from "../../Foods/elements/InputBox";

export default function ReviewForm() {
  const classes = useStyle();
  const [file, setFile] = useState<any>();

  const setReviewFile = (e: any) => {
    let imgFile = e.target.files[0];
    setFile(imgFile);
  }

  const formData = new FormData();
  const addReview = async () => {
    formData.append('reviewAdminSetting', file);
    const token = getCookie('is_login')

    axios({
      method: 'post',
      url: '/admin/review',
      data: formData,
      headers: { "Content-Type": "multipart/form-data", adt: token}
    }).then((res) => {
      console.log(res);
    }).catch((err) => {
      console.log(err, '리뷰등록 실패');
    })
    
  }

  return (
    <>
      <Grid className={classes.input}>
        <InputBox type='file' placeholder='리뷰를 등록해주세요' setState={setReviewFile} value={''}/>

        <Grid 
        className={classes.buttonContainer}
        container 
        justifyContent='center' 
        alignItems='center'
        >
          <Grid onClick={addReview}>
            <span >등록하기</span>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}

const useStyle = makeStyles(() => 
  createStyles({
    input: {
      width: '100%',
      margin: '20px auto',
      padding: '40px 100px',
      boxSizing: 'border-box',
      background: '#fff',
      boxShadow: '0 10px 30px rgb(0 0 0 / 20%)',
    },
    buttonContainer: {
      width: '50%',
      margin: 'auto',
      marginTop: 30,
      '& div': {
        width: 100,
        height: 30,
        background: '#67a56f',
        borderRadius: 5,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
          fontSize: 14,
          color: '#fff',
        }
      }
    }
  })
)